<template>
    <div class="details pages pl-18 pr-18 mt-20" :class="[!Dark ? '' : 'dark', collapsed ? 'hide' : '' ]">
        <div class="scroll" v-loading="loading">
            <div class="show" v-show="!loading">
                <div class="doctor-box-top">
                    <div class="top-left" v-if="patientInfo">
                        <div class="left-avatar flex align-center">
                            <img :src="patientInfo.avatar" :onerror="notHeaders" alt="">
                        </div>
                        <div class="left-info">
                            <div class="intro">
                                <div class="name">姓名：
                                    <span>{{patientInfo.agency_nickname}}</span>
                                    <!--  -->
                                    <i class="el-icon-edit-outline" @click="edit(patientInfo.agency_nickname,1)"></i>
                                </div>
                                <span>
                                    <i class="color">年龄：</i>
                                    {{patientInfo.age}}岁{{ Number(patientInfo.birthday) != 0 && patientInfo.birthday != '' ? '（' + getDate(patientInfo.birthday * 1000) + '）' : '' }}
                                </span>
                                <span>
                                    <i class="color">类型：</i>
                                    {{patientInfo.suffer_type_value}}
                                </span>
                            </div>
                            <div class="range">
                                <div class="name" v-if="patientInfo">
                                    达标范围：
                                    <span>{{patientInfo.target_low + '-' + patientInfo.target_high}}mmol/L</span>
                                </div>
                                <div class="name" v-if="patientInfo">
                                    预警范围：
                                    <span>&lt;={{patientInfo.alert_low + 'mmol/L或者&gt;=' + patientInfo.alert_high}}mmol/L</span>
                                </div>
                            </div>
                            <div class="note">
                                <em class="color">备注：</em>
                                <span v-if="patientInfo" style="margin-right: 0px;">{{patientInfo.doctor_note||'--'}}</span>
                                <i class="el-icon-edit-outline" @click="edit(patientInfo.doctor_note,2)" />
                            </div>
                        </div>
                    </div>
                    <div class="top-right">
                        <div class="fangan" @click="addPatientPlan()">
                            <div class="title">控糖方案</div>
                            <div class="sub_title">为患者设置控糖方案</div>
                            <img src="@/assets/images/icons/icon-weixiu.png" alt="">
                        </div>
                        <div class="dangan" @click="healthDocumentShow = true">
                            <div class="title">健康档案</div>
                            <div class="sub_title">患者的详细健康数据</div>
                            <img src="@/assets/images/icons/icon-dangan.png" alt="">
                        </div>
                    </div>
                </div>

                <el-tabs class="pion-rel mt-20" v-model="tab" type="border-card">
                    <el-tab-pane label="每日数据" name="0" />
                    <el-tab-pane label="动态统计" name="1" />
                    <el-tab-pane label="指血统计" name="3" />
                    <el-tab-pane label="佩戴记录" name="2" />
                    <el-tab-pane label="逆转方案" name="4" v-if="Number(agencyInfo.is_reverse) == 1" />
                    <el-tab-pane label="处方列表" name="5" v-if="Number(agencyInfo.is_reverse) == 1" />
                    <div class="buttons pion-abs flex">
                        <el-button class="line-height-32 font-12 ml-15 font-normal" plain v-if="tab == '0'" @click="showInterpret = true">每日数据解读</el-button>
                    </div>
                </el-tabs>
                <div class="contents">
                    <TabsOne :allData="allData" :Dark="Dark" :lifeRecord="lifeRecord" :patientInfo="patientInfo" v-show="tab == '0'" @dateChange="getDayDate" @addNote="addNote" @upDataPumpRecordInfo="upDataPumpRecordInfo" />
                    <!--  :deviceId="device_id" @selectMonth="selectMonth" 旧版本，切换年月日 -->
                    <TabsTwo :statistics="statistics" :Tab="tab" :Dark="Dark" :eight="eight" v-show="tab == '1'" @selectTimes="selectTimes" @getDataExcel="getDataExcel" />
                    <TabsThr :patientInfo="patientInfo" :deviceList="deviceList" :Dark="Dark" v-show="tab == '2'" @changePage="changePage" />
                    <TabsFour :fingerBlood="fingerBlood" :Dark="Dark" :Tab="tab" @selectFingerTimes="selectFingerTimes" v-show="tab == '3'" />
                    <TabsFive :patientInfo="patientInfo" :UserReversePlan="UserReversePlan" @uploadDate="uploadDate" :Dark="Dark" v-show="tab == '4'"/>
                    <TabsSix :patientInfo="patientInfo" :Dark="Dark" :drugList="drugList" @upDataDrug="upDataDrug" v-if="tab == '5'" />
                </div>
            </div>
        </div>
        <Edit :Dark="Dark" v-if="patientInfo" :editData="editData" @close="close" @isEditUserName="isEditUserName" @isEditNote="isEditNote" />
        <AddPlan :parameter="parameter" :Dark="Dark" @closePlan="closePlan" />
        <ShowPatientDetail :show="healthDocumentShow" :Dark="Dark" @close="healthDocumentShow = false" @edit="editPatient" />
        <AddPatient :Edit="true" :Show="showAddPatient" :Dark="Dark" :patientInfo="patientDetail" @close="showAddPatient = false" @ok="showAddPatient = false" />
        <Interpret :show="showInterpret" :Dark="Dark" :date="today" :text="allData.analysis" v-if="allData" @close="closeInter" />
    </div>
</template> 

<script>

    import { getPatientInfo, getDataByDate, getUserLifeRecordByDay, getDeviceStatByDays, getStatByDays, getUserDeviceList, addEditUser, bloodsugarExport, getUserReversePlan, getPrescriptionList } from 'quest'
    import { TabsOne, TabsTwo, TabsThr, TabsFour, TabsFive, TabsSix } from '_c/Patients/index';
    import { Edit, AddPlan, ShowPatientDetail, AddPatient, Interpret } from '_c/Model/index';
    import { getItem, removeItem, time } from '_js/Cache';
// import everyDaysLineVue from '../../../components/print/everyDaysLine.vue';
    export default {
        components:{
            TabsOne,
            TabsTwo,
            TabsThr,
            TabsFour,
            TabsFive,
            TabsSix,
            Edit,
            Interpret,
            AddPlan,
            ShowPatientDetail,
            AddPatient
        },
        props:{
            Dark: Boolean,
            collapsed: Boolean
        },
        watch:{ 
            today(){
                this.getDataByDate();
                this.getUserLifeRecordByDay();
            },
            tab(val){
                this.loading = true;
                const end = time(new Date(), 'YYYY-MM-DD'),
                        start = time( new Date(new Date().getTime() -  6 * 24 * 3600 * 1000),  'YYYY-MM-DD');
                if( val == '0' ){
                    this.getPatientInfo();
                    this.getDataByDate();
                    this.getUserLifeRecordByDay();
                }
                if( val == '1' ){
                    // if(this.device_id != 0){
                        // const start = '2022-10-28',
                        //       end = '2022-11-03'
                        this.getDeviceStatByDays(start,end);
                    // }else{
                    //     this.getStatByMonth();
                    // }
                }
                if( val == '2' ){
                    this.getUserDeviceList()
                }
                if(val == '3'){
                    this.getStatByDays(start,end)
                    this.loading = false
                }
                if (val == '4'){
                    this.getUserReversePlan({uid: this.id})
                }
                if(val == '5'){
                    this.getPrescriptionList({uid: this.id,page: this.drugListPage})
                }
            }
        },
        data(){
            return{
                id: this.$route.query.id,
                loading: true,
                patientInfo: null,
                showInterpret: false,
                tab: '0',
                healthDocumentShow: false,
                showAddPatient: false,
                parameter:{
                    from: 'details',
                    show: false,
                    id: 0,
                },
                patientDetail: null,
                allData: null,
                today: '',
                lifeRecord: null,
                statistics: null,
                eight: null,
                fingerBlood: null,
                device_id: '',
                deviceList: [],
                annal:{
                    page: 1,
                    limit: 12
                },
                editData:{
                    data: '',
                    show: false,
                    type: 0,
                },
                notHeaders: 'this.src="' + require('@/assets/images/error/notheader.png') + '"',
                fingerEight: null,
                UserReversePlan: null,
                drugList: [],
                drugListPage: 1,
                agencyInfo: getItem('agencyInfo')
            }
        },
        methods:{
            getPatientInfo(){
                getPatientInfo({uid: this.id}).then((res) => {
                    if(res.data.code == 100000){
                        this.patientInfo = res.data.data;
                        this.device_id = res.data.data.device_id;
                        this.loading = false;
                    }
                }).catch(() => {
                    this.$message.error('获取用户信息失败！');
                });
            },
            getDate(val){
                return time(new Date(val), 'YYYY-MM-DD');
            },
            getDayDate(val){
                this.today = val;
            },
            getDataByDate(){
                getDataByDate({
                    user_id: this.$route.query.id,
                    date_time: this.today
                }).then( res =>{
                    if(res.data.code == 100000){
                        this.allData = res.data.data;
                        this.loading = false;
                    }
                }).catch(() => {
                    this.$message.error('获取用户数据失败！');
                    this.loading = false;
                });
            },
            closeInter(){
                this.showInterpret = false;
                this.getDataByDate();
            },
            getUserLifeRecordByDay(){
                getUserLifeRecordByDay({
                    uid: this.$route.query.id,
                    date: this.today
                }).then( res =>{
                    //  && JSON.stringify(res.data.data) != '{}'
                    if(res.data.code == 100000){
                        this.addStr(res.data.data);
                        this.loading = false;
                    }else{
                        this.$message.error('获取用户数据失败！');
                        this.loading = false;
                    }
                }).catch(() => {
                    this.$message.error('获取用户数据失败！');
                    this.loading = false;
                });
            },
            getDeviceStatByDays(start,end) {
                getDeviceStatByDays({
                    uid: this.id,
                    start_day: start,
                    end_day: end
                }).then ( res =>{
                    if(res.data.code == 100000){
                        let {
                            device_info,
                            sports,
                            food,
                            eight,
                            day_line,
                            day_tir,
                            envelope,
                            tir,
                            user_info,
                            timepoint,
                            average,
                            wave
                        } = res.data.data;

                        this.statistics = {
                            allData: res.data.data,
                            device_info: device_info,
                            sports: sports,
                            food: food,
                            day_line: day_line,
                            envelope: envelope,
                            tir: tir,
                            user_info: user_info,
                            timepoint: timepoint,
                            average: average,
                            wave: wave
                        }

                        let day_tir_val = Object.values(day_tir);
                        let day_tir_key = Object.keys(day_tir);
                        day_tir_val.forEach((item,index)=>{
                            item.date = day_tir_key[index].slice(5)
                        })
                        this.eight = eight;
                        this.statistics.day_tir = day_tir_val;
                        this.loading = false;
                    }
                }).catch( ()=>{
                    this.loading = false;
                    this.$message.error('接口异常')
                    this.loading = false;
                });

            },
            getStatByDays(start,end){
                getStatByDays({
                    uid: this.id,
                    start_day: start,
                    end_day: end
                }).then(res=>{
                    if(res.data.code == 100000){
                        const data = res.data.data;
                        this.fingerBlood = {
                            allData: data,
                            tir: data.tir,
                            total: data.total,
                            ave_bloodsugar: data.ave_bloodsugar,
                            highBlood: data.highest_bloodsugar,
                            lowBlood: data.lowest_bloodsugar,
                            rate: data.rate.normal,
                            type_avg: data.type_avg,
                            target_low: data.user_info.target_low,
                            target_high: data.user_info.target_high,
                            eight: data.eight,
                        }
                        this.loading = false;
                    }
                })
            },
            getUserDeviceList(){
                getUserDeviceList({
                    user_id: this.id,
                    ...this.annal
                }).then( res => {
                    if(res.data.code == 100000){
                        this.deviceList = res.data.data;
                        this.loading = false
                    }
                })
            },
            changePage(page){
                this.loading = true;
                this.annal.page = page;
                this.getUserDeviceList();
            },
            close(){
                this.editData = {
                    data: '',
                    show: false,
                    type: 0,
                }
            },
            closePlan(show){
                this.parameter.show = false;
                window.isCloseHint = false;
                this.parameter.id = 0;
                if(!show){
                    for(let i = 0; i < 7 ; i++ ){
                        removeItem('day' + i);
                    }
                }
            },
            addPatientPlan(){
                this.parameter.show = true;
                this.parameter.id = Number(this.$route.query.id);
            },
            editPatient(){
                this.showAddPatient = true;
                this.healthDocumentShow = false;
                addEditUser({
                    uid: this.id
                }).then(res => {
                    if(res.data.code == 100000) {
                        this.patientDetail = res.data.data;
                    }else{
                        this.$message.error(res.data.msg);
                        this.showAddPatient = false;
                    }
                })
            },

            addStr(data){
                let timer = setInterval( () =>{
                    if(this.allData){
                        if(this.allData.is_cgm == 1 ){
                            let str = [];
                            if(data.length > 0){
                                data.map( item =>{
                                    item.editRemark = false;
                                    item.remarkValue = item.record.note;
                                    str.push(item);
                                })
                                this.lifeRecord = str;
                            }else{
                                this.lifeRecord = str;
                            }
                        }else{
                            this.lifeRecord = data;
                        }
                        clearInterval(timer);
                    }
                },100)
            },
            edit(data,t){
                this.editData.data = data,
                this.editData.show = true,
                this.editData.type = t;
            },
            addNote(data){
                this.lifeRecord[data.index].record.note = data.note;
                this.lifeRecord[data.index].editRemark = false;
            },
            // selectMonth(val){
            //     let Y = val.getFullYear(),
            //         M = ( val.getMonth() + 1 ) > 10 ? val.getMonth() : '0' + ( val.getMonth() + 1 );
            //     this.getStatByMonth( Y + '-' + M );
            // }
            selectTimes(start,end){
                this.getDeviceStatByDays(start,end);
            },
            getDataExcel(start,end){
                const md5 = require('js-md5');
                let data = {
                    uid: this.id,
                    export_sign: md5(this.id + 'yja2019_fcz'),
                    token: this.statistics.user_info.token || '',
                    start_day: start || '',
                    end_day: end || ''
                }
                bloodsugarExport(data).then( res =>{
                    let downloading = this.$message({
                        message: '下载中...',
                        type: 'info',
                        iconClass: 'el-icon-loading',

                    });
                    if(!res.data.code){
                        downloading.close();
                        let http = process.env.VUE_APP_ENV === 'test' ? 'https://test.cgmcare.cn/api' : 'https://td.cgmcare.cn/api',
                            fileUrl = res.config.url;
                        window.open(http + fileUrl, '_blank');
                        this.$message.success('下载成功！');
                    }else{
                        downloading.close();
                        this.$message.error(res.data.msg);
                    }
                })
            },
            selectFingerTimes(start,end){
                this.getStatByDays(start,end)
            },
            isEditUserName(val){
                this.patientInfo.agency_nickname = val.agency_nickname;
            },
            isEditNote(val){
                this.patientInfo.doctor_note = val.doctor_note;
            },
            getUserReversePlan(val){
                getUserReversePlan(val).then( res =>{
                    if(res.data.code == 100000){
                        this.UserReversePlan = res.data.data;
                        this.loading = false
                    }
                })
            },
            getPrescriptionList(val){
                getPrescriptionList(val).then( res =>{
                    if(res.data.code == 100000){
                        this.drugList = res.data.data;
                        this.loading = false;
                    }else{
                        this.$message.error('获取处方列表失败！')
                    }
                })
            },
            uploadDate(){
                this.getUserReversePlan({uid: this.id})
            },
            upDataDrug(){
                this.getPrescriptionList({uid: this.id,page: this.drugListPage})
            },
            upDataPumpRecordInfo(PumpRecordInfo){
                this.patientInfo.pump_record_info = PumpRecordInfo;
            }
        },
        mounted(){
            this.getPatientInfo();
        },
    }
</script>

<style scoped lang="less">
    .details{
        height: ~'calc(100% - 70px)';
        .scroll{
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            border-radius: 10px;

            .doctor-box-top {
                width: 100%;
                height: 120px;
                background: #FFFFFF;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 18px 20px;
                transition: .3s;

                .doctor-box-bottom {
                    width: 100%;
                    margin-top: 20px;

                    .tabs-top {
                        width: 100%;
                        display: flex;
                        cursor: pointer;

                        div {
                            width: 95px;
                            height: 46px;
                            line-height: 46px;
                            text-align: center;
                            border-radius: 6px 6px 0px 0px;
                            background: #EAEDF1;
                            color: #9F9F9F;
                            font-size: 14px;
                            margin-right: 2px;
                        }

                        .isActive {
                            color: #333;
                            background: #fff;
                        }
                    }

                    .tabs-content {
                        width: 100%;
                        background: #fff;
                        padding: 20px 30px;
                    }
                }

                .top-left {
                    display: flex;

                    .left-avatar {
                        margin-right: 18px;

                        img {
                            width: 56px;
                            height: 56px;
                            border-radius: 50%;
                        }
                    }

                    .left-info {

                        .intro,
                        .note,
                        .range {
                            display: flex;
                            margin: 10px 0 12px;
                            em{
                                color: #515a6e;
                            }
                            span {
                                display: flex;
                                font-size: 12px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #242424;
                                line-height: 14px;
                                margin-right: 50px;

                                i{
                                    font-style: normal;
                                    color: #515a6e;
                                }
                            }
                        }

                        .note {
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #242424;
                            line-height: 14px;
                            display: flex;
                            align-items: center;

                            span{
                                display: block;
                                max-width: 300px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            em{
                                font-style: normal;
                            }

                            i{
                                font-size: 18px;
                                margin-left: 10px;
                                color: #72e0c9;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .top-right {
                    width: 320px;
                    height: 70px;
                    display: flex;

                    .fangan {
                        background: #304ECE;
                        box-shadow: 0px 1px 3px 0px rgba(235,235,235,0.5);
                        border-radius: 4px;
                        padding: 13px 15px;
                        position: relative;

                        .title {
                            font-size: 14px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                            line-height: 15px;
                        }

                        .sub_title {
                            font-size: 12px;
                            color: #FFFFFF;
                            line-height: 15px;
                            margin-top: 10px;
                        }

                        img {
                            width: 35px;
                            height: 42px;
                            position: absolute;
                            bottom: 10px;
                            right: 10px;
                        }
                    }
                    .dangan {
                        background: #16CCA6;
                        box-shadow: 0px 1px 3px 0px rgba(235,235,235,0.5);
                        border-radius: 4px;
                        padding: 13px 15px;
                        position: relative;
                        margin-left: 10px;

                        .title {
                            font-size: 14px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                            line-height: 15px;
                        }

                        .sub_title {
                            font-size: 12px;
                            color: #FFFFFF;
                            line-height: 15px;
                            margin-top: 10px;
                        }

                        img {
                            width: 45px;
                            height: 42px;
                            position: absolute;
                            bottom: 10px;
                            right: 10px;
                        }
                    }
                }

                .name{
                    display: flex;
                    align-items: center;
                    justify-content: flex;
                    font-size: 12px;
                    margin-right: 50px;

                    span{
                        display: inline !important;
                        margin-right: unset !important;
                        &:focus{
                            outline: none;
                        }
                    }

                    i{
                        font-size: 18px;
                        margin-left: 10px;
                        color: #72e0c9;
                        cursor: pointer;
                    }
                }
            }
        }
        .scroll /deep/ .el-tabs{
            background: none;
            border: none;
            box-shadow: unset;

            .el-tabs__header.is-top{
                border: none;
            }

            .el-tabs__nav{

                .el-tabs__item{
                    width: 95px;
                    height: 46px;
                    line-height: 46px;
                    text-align: center;
                    border-radius: 6px 6px 0 0;
                    background: #eaedf1;
                    color: #9f9f9f;
                    font-size: 14px;
                    margin-right: 3px;
                    border: none !important;
                }

                .el-tabs__item.is-active{
                    border: none;
                    background: white;
                }
            }

            .el-tabs__content{
                overflow: visible;
            }

            .el-tabs__content{
                padding: 0px;
            }

            .buttons{
                top: -38px;
                right: 0px;
                z-index: 999;

                button{
                    margin-left: auto;
                    width: 120px;
                    height: 33px!important;
                    padding: 0;
                    background: #eaedf1;
                    margin-right: 20px;

                    &:hover{
                        border-color: var(--defalutColor) !important;
                        color: var(--defalutColor) !important;
                        transition: .3s;
                    }
                    &:focus{
                        border-color: #dcdfe6;
                        color: #606266;
                    }
                }

                button.border-none{
                    width: auto;
                    color: #e8e8e8;
                }
            }
        }
        .show{
            .contents{
                padding: 20px 30px;
                background: var(--white);
                transition: .3s;
            }
        }
        &.dark{
            .doctor-box-top{
                background: #202025 !important;
                transition: .3s;
                .name,
                .color{
                    color: #c1c1c1 !important;
                }
                span{
                    color: #7f7f7f !important;
                }
            }
            .scroll /deep/ .el-tabs{
                .el-tabs__header{
                    background-color: #202025;
                    .el-tabs__item{
                        background: var(--black);
                        color: #c9c9c9;
                    }
                    .el-tabs__item.is-active{
                        background: #0c0c0c;
                        color: #c1c1c1;
                    }
                }
                .buttons{
                    button{
                        background: unset;
                        color: white !important;
                        border-color: #7d7d7d;
                    }
                }
            }
            .contents{
                background: #202025 !important;
                transition: .3s;
            }
        }
    }

    @media screen {
        @media (max-width: 1366px) and (min-width: 768px) {
            .details{
                .doctor-box-top{
                    .left-info{
                        .intro{
                            align-items: center;
                        }
                        .name{
                            margin-right: 10px !important;
                        }
                        span{
                            margin-right: 10px !important;
                        }
                    }
                    .fangan,
                    .dangan{
                        padding: 13px 10px !important;
                    }
                }
            }
        }
    }
</style>