<template>
    <div class="everyDay" :class="[!Dark ? '' : 'dark']" v-loading="!loading">
        <div class="dayDate">
            <div class="date flex pion-rel justify-center align-center">
                <div class="info pion-abs" v-if="allData.is_cgm == 1 && allData.device_info != '' && allData.device_info">
                    <img src="@/assets/images/Patient/source1.png" v-if="Number(allData.device_info.source) == 1" alt="">
                    <img src="@/assets/images/Patient/source2.png" v-if="Number(allData.device_info.source) == 2" alt="">
                    <img src="@/assets/images/Patient/source3.png" v-if="Number(allData.device_info.source) == 3" alt="">
                    <img src="@/assets/images/Patient/source4.png" v-if="Number(allData.device_info.source) == 4" alt="">
                    <img src="@/assets/images/Patient/source5.png" v-if="Number(allData.device_info.source) == 6" alt="">
                    <div class="center-box">
                        <div class="name">
                        检测设备：<p>{{allData.device_info.device_type}}</p>
                        </div>
                        <div class="number">
                        设备编号： <p>{{allData.device_info.device_name}}</p>
                        </div>
                    </div>
                </div>
                <div class="notInfo" v-else></div>
                <div class="select font-weight">
                    <i class="el-icon-arrow-left font-weight mr-10" @click="changeDateByIcon(-1)"></i>
                    {{today}}
                    <i class="el-icon-arrow-right font-weight ml-10" @click="changeDateByIcon(1)"></i>
                </div>
                <el-date-picker class="pion-abs dateSelect" v-model="selectDate" :append-to-body="false" align="right" :picker-options="pickerOptions" type="date" placeholder="选择日期" @change="selectDay" clearable />
            </div>
            <div class="isCgm" v-if="allData.is_cgm == 1">
                <div class="item xuetangzhi">
                    <div class="title" v-if="date_time == today">{{allData.newest_blood_sugar.create_time_str ? allData.newest_blood_sugar.create_time_str : '--' }}<span class="ml-10" :class="[allData.newest_blood_sugar.target_level == 0 ? 'normal' : allData.newest_blood_sugar.target_level > 0 ? 'high' : 'low']">{{ allData.newest_blood_sugar.target_level == 0 ? '达标' : allData.newest_blood_sugar.target_level > 0 ? '偏高' : '偏低' }}</span>
                    </div>
                    <div class="title" v-else>{{today.split('-')[1] + '-' + today.split('-')[2]}} 平均血糖值</div>
                    <div class="number clear" v-if="date_time == today">
                        <p class="float-left">{{allData.newest_blood_sugar.blood_sugar ? allData.newest_blood_sugar.blood_sugar : "¯.¯"}}</p>
                        <div class="right float-left">
                            <i class="el-icon-top font-weight" :style="{'transform': allData.newest_blood_sugar.trend == 1 || allData.newest_blood_sugar.trend == 0 ? 'rotate(90deg)' : allData.newest_blood_sugar.trend == 5 ? 'rotate(45deg)' : allData.newest_blood_sugar.trend == 20 ? 'rotate(180deg)' : allData.newest_blood_sugar.trend == 10 ? 'rotate(135deg)' : ''}"></i>
                            <span class="pl-5">mmol/L</span>
                        </div>
                    </div>
                    <div class="number clear" v-else>
                        <p class="float-left">{{allData.avg_bloodsugar}}</p>
                        <div class="right">
                            <i class="el-icon-top font-weight" :style="{'transform': allData.newest_blood_sugar.trend == 1 || allData.newest_blood_sugar.trend == 0 ? 'rotate(90deg)' : allData.newest_blood_sugar.trend == 5 ? 'rotate(45deg)' : allData.newest_blood_sugar.trend == 20 ? 'rotate(180deg)' : allData.newest_blood_sugar.trend == 10 ? 'rotate(135deg)' : ''}" style="color: white"></i>
                            <span class="pl-5">mmol/L</span>
                        </div>
                    </div>
                    <div class="average" v-if="date_time == today">
                        平均值：<span>{{allData.avg_bloodsugar ? allData.avg_bloodsugar : '--' }}</span>&nbsp;&nbsp;&nbsp;&nbsp;达标率：{{allData.common_rate}}%
                    </div>
                    <div class="average" v-else>
                        达标率：{{allData.common_rate}}%
                    </div>
                </div>
                <div class="item process" v-if="allData.is_cgm">
                    <Process :tir="allData.tir" :flag="true" :Dark="Dark" />
                </div>
                <div class="item indicator" v-if="!allData.is_cgm">
                    <div class="main-con text">
                        <span>偏高率</span>
                        <span>{{allData.rate.high*100 | tofixed}}%</span>
                    </div>
                    <div class="main-con text">
                        <span>达标率</span>
                        <span>{{allData.rate.normal*100 | tofixed}}%</span>
                    </div>
                    <div class="main-con text">
                        <span>偏低率</span>
                        <span>{{allData.rate.low*100 | tofixed}}%</span>
                    </div>
                </div>
                <div class="item indicator">
                    <div class="indicator-item mt-17">
                        <div class="main-con text">
                            <span>最高数值</span>
                            <span>{{allData.max_bloodsugar}}mmol/L</span>
                        </div>
                    </div>
                    <div class="indicator-item mt-17">
                        <div class="main-con text">
                            <span>最低数值</span>
                            <span>{{allData.min_bloodsugar}}mmol/L</span>
                        </div>
                    </div>
                    <div class="indicator-item mt-17">
                        <div class="main-con text">
                            <span>波动幅度</span>
                            <span>{{allData.bs_range}}mmol/L</span>
                        </div>
                    </div>
                </div>
                <div class="item indicator-detail">
                    <div class="row-list">
                        <img src="@/assets/images/icons/u.png" alt="">
                        <p><span class="w1" style="margin-left: 0px"><i>碳</i><i>水</i></span><span>{{allData.liferecord_total.foodrecord_total | empty}}<em>克</em></span></p>
                    </div>
                    <div class="row-list">
                        <img src="@/assets/images/icons/ku.png" alt="">
                        <p>胰岛素 <span>{{allData.liferecord_total.insulin_total | empty}}<em>U</em></span></p>
                    </div>
                    <div class="row-list">
                        <img src="@/assets/images/icons/ci.png" alt="">
                        <p>口服药 <span>{{allData.liferecord_total.medicinerecord_total | empty}} <em>次</em></span></p>
                    </div>
                    <div class="row-list">
                        <img src="@/assets/images/icons/kal.png" alt="">
                        <p><span class="w1" style="margin-left: 0px"><i>运</i><i>动</i></span><span>{{allData.liferecord_total.sportsrecord_total | empty}}<em>千卡</em></span></p>
                    </div>
                </div>
            </div>
            <div class="not_cgm" v-if=" allData.is_cgm == 0 ">

                <div class="tip_data">

                    <div class="tip_left">

                    <div class="date_list">
                        <h3>达标率</h3>
                        <p>%</p>
                        <h1>{{allData.rate.normal === '' ? '--' : allData.rate.normal * 100}}</h1>
                    </div>
                    <div class="date_list">
                        <h3>最高值</h3>
                        <p>mmol/L</p>
                        <h1>{{allData.max_bloodsugar === '' ? '--' : allData.max_bloodsugar }}</h1>
                    </div>
                    <div class="date_list">
                        <h3>最低值</h3>
                        <p>mmol/L</p>
                        <h1>{{allData.min_bloodsugar === '' ? '--' : allData.min_bloodsugar }}</h1>
                    </div>

                    </div>
                    <div class="tip_right">

                    <div class="list">
                        <img src="@/assets/images/icons/u.png" alt="">
                        <p>碳<span>水</span></p>
                        <h4>{{allData.liferecord_total.foodrecord_total}}克</h4>
                    </div>
                    <div class="list">
                        <img src="@/assets/images/icons/ku.png" alt="">
                        <p>胰岛素</p>
                        <h4>{{allData.liferecord_total.insulin_total}}U</h4>
                    </div>
                    <div class="list">
                        <img src="@/assets/images/icons/ci.png" alt="">
                        <p>口服药</p>
                        <h4>{{allData.liferecord_total.medicinerecord_total}}次</h4>
                    </div>
                    <div class="list">
                        <img src="@/assets/images/icons/kal.png" alt="">
                        <p>运<span>动</span></p>
                        <h4>{{allData.liferecord_total.sportsrecord_total}}千卡</h4>
                    </div>

                    </div>

                </div>
            </div>
            <div class="doctor_order" v-if="allData.analysis != '' ">
                <div class="doctor_info">
                    <img src="@/assets/images/error/notheader.png" alt="">
                    <p>每日解读</p>
                </div>
                <div class="order">
                    <p>{{allData.analysis}}</p>
                </div>
            </div>
            <div class="pumpInfo mt-20 pt-20 pb-20 ml-15 mr-20" v-if="patientInfo.pump_record_info.pump_info != null" >
                <div class="background flex justify-between pt-20 pl-20 pr-20 pb-20 radius-5">
                    <div class="num flex">
                        <p :class="patientInfo.pump_record_info.pump_info.work_status == 0 ? 'vlow' : patientInfo.siis == '1' ? 'normal' : ''">胰岛素泵：{{ patientInfo.siis == '1' ? '' : patientInfo.pump_record_info.pump_info.custom_title }}<span v-if="patientInfo.siis == '1'">{{ patientInfo.pump_record_info.pump_info.custom_title }}</span><span v-if="patientInfo.pump_record_info.pump_info.work_status == 0">{{ patientInfo.pump_record_info.pump_info.work_status == 0 ? '（已暂停）' : '' }}</span> </p>
                        <p class="ml-20" v-if="patientInfo.pump_record_info.pump_info.source == 1 || patientInfo.pump_record_info.pump_info.source == 3" :class="[ Number(patientInfo.pump_record_info.pump_info.left_dose / patientInfo.pump_record_info.pump_info.max_capacity * 100) <= 25 ? 'vlow' : Number(patientInfo.pump_record_info.pump_info.left_dose / patientInfo.pump_record_info.pump_info.max_capacity * 100) <= 50 ? 'low' : 'normal' ]">剩余药量：<span>{{ patientInfo.pump_record_info.pump_info.left_dose }} U</span>
                        </p>
                        <p class="ml-20" v-if="patientInfo.pump_record_info.pump_info.source == 1 || patientInfo.pump_record_info.pump_info.source == 3" :class="[patientInfo.pump_record_info.pump_info.battery <= 25 ? 'vlow' : patientInfo.pump_record_info.pump_info.battery <= 50 ? 'low' : 'normal' ]">剩余电量：<span>{{ patientInfo.pump_record_info.pump_info.battery }}%</span></p>
                    </div>
                    <div class="left flex">
                        <p>方案名称：{{ patientInfo.pump_record_info.profile_info.title }}
                            <el-tooltip placement="top">
                                <div slot="content" class="pt-20 pb-20" style="width: 300px; font-size: 16px">
                                    <div class="flex" style="width: 100%;">
                                        <p class="text-right" style="width: 160px;">方案名称：</p>
                                        <p>{{ patientInfo.pump_record_info.profile_info.title }}</p>
                                    </div>
                                    <div class="flex mt-15" style="width: 100%;">
                                        <p class="text-right" style="width: 160px;">胰岛素吸收时长：</p>
                                        <p>{{ patientInfo.pump_record_info.profile_info.insulin_absorb_time }}小时</p>
                                    </div>
                                    <div class="flex mt-15" style="width: 100%;">
                                        <p class="text-right" style="width: 160px;">碳水吸收时长：</p>
                                        <p>{{ patientInfo.pump_record_info.profile_info.carb_absorb_time }}小时</p>
                                    </div>
                                    <div class="flex mt-15" style="width: 100%;">
                                        <p class="text-right" style="width: 160px;">胰岛素敏感系数：</p>
                                        <p>{{ patientInfo.pump_record_info.profile_info.now_isf }}</p>
                                    </div>
                                    <div class="flex mt-15" style="width: 100%;">
                                        <p class="text-right" style="width: 160px;">碳水敏感系数：</p>
                                        <p>{{ patientInfo.pump_record_info.profile_info.now_icr }}</p>
                                    </div>
                                    <div class="flex mt-15" style="width: 100%;">
                                        <p class="text-right" style="width: 160px;">目标血糖值：</p>
                                        <p>{{ patientInfo.pump_record_info.profile_info.now_target_bloodsugar }}</p>
                                    </div>
                                </div>
                                <el-button style="border: unset; padding: unset; background: unset;"><i class="el-icon-s-opportunity planButton"></i></el-button>
                            </el-tooltip>
                        </p>
                        <p class="ml-20">近五日统计
                            <el-tooltip placement="top">
                                <div slot="content" class="pt-20 pb-20 pl-20 pr-20" style="width: 375px; font-size: 16px">
                                    <div class="dayCounTable flex justify-between align-center" v-for="(item,index) in patientInfo.pump_record_info.pump_info.inject_history" :key="index" :style="{'margin-top': index > 0 ? '20px' : ''}">
                                        <div class="date">{{ item.date }}</div>
                                        <div class="sum text-center">
                                            <h6 style="font-size: 12px;">输入总量</h6>
                                            <p class="font-weight flex align-end mt-5">{{ item.total }}
                                                <span class="ml-5" style="font-size: 12px;">U</span>
                                            </p>
                                        </div>
                                        <div class="max text-center">
                                            <h6 style="font-size: 12px;">大剂量</h6>
                                            <p class="font-weight flex align-end mt-5">{{ item.bolus }}
                                                <span class="ml-5" style="font-size: 12px;">U</span>
                                            </p>
                                            <span class="mt-5" style="font-size: 12px;">{{ item.bolus_ratio }}%</span>
                                        </div>
                                        <div class="base text-center">
                                            <h6 style="font-size: 12px;">基础率</h6>
                                            <p class="font-weight flex align-end mt-5">{{ item.basal }}
                                                <span class="ml-5" style="font-size: 12px;">U</span>
                                            </p>
                                            <span class="mt-5" style="font-size: 12px;">{{ item.basal_ratio }}%</span>
                                        </div>
                                    </div>
                                </div>
                                <el-button style="border: unset; padding: unset; background: unset;"><i class="el-icon-s-data planButton"></i></el-button>
                            </el-tooltip>
                        </p>
                        <p class="ml-20 flex align-center">
                            <span class="flex align-center">远程操作<em class="badge ml-5 mr-5 text-center" v-if="patientInfo.pump_record_info.pump_info.siis_record_count > 0">{{ patientInfo.pump_record_info.pump_info.siis_record_count }}</em></span>
                            <el-tooltip placement="top" popper-class="remote-button">
                                <div slot="content" class="pt-20 pb-20 remote-cont" style="width: 400px; font-size: 16px">
                                    <div class="row-1 flex justify-between align-center row" v-if="patientInfo.is_open_siis == 1">
                                        <div class="run-type flex">
                                            <div class="text">闭环状态：</div><span>{{ patientInfo.siis == 1 ? '已启用' : '未启用' }}</span>
                                        </div>
                                        <div>
                                            <el-switch v-model="loopType" @change="switchLoop" active-color="#13ce66"></el-switch>
                                        </div>
                                    </div>
                                    <div class="row-1 flex justify-between align-center row" :class="[patientInfo.is_open_siis == 1 ? ' mt-20' : '' ]">
                                        <div class="run-type flex">
                                            <div class="text">运行状态：</div><span> {{ patientInfo.pump_record_info.pump_info.work_status == 1 ? '运行中' : '已暂停' }}</span>
                                        </div>
                                        <div class="remote-btn run" @click="settingRunType">
                                            远程{{ patientInfo.pump_record_info.pump_info.work_status == 1 ? '暂停' : '恢复' }}
                                        </div>
                                    </div>
                                    <div class="row-2 flex justify-between align-center row mt-15">
                                        <div class="run-type flex">
                                            <div class="text">当前基础率：</div>{{ Number(patientInfo.pump_record_info.profile_info.now_basal).toFixed(2) }} <span>U/H</span>
                                        </div>
                                        <div class="remote-btn basal" @click="remoteBasalDialog = !remoteBasalDialog">
                                            远程基础率
                                        </div>
                                    </div>
                                    <div class="row-3 flex justify-between align-center row mt-15">
                                        <div class="run-type flex">
                                            <div class="text">
                                                最新大剂量：
                                            </div>{{ Number(patientInfo.pump_record_info.pump_info.last_dose).toFixed(3) > 0 ? getRemoteTimes(new Date(Number(patientInfo.pump_record_info.pump_info.last_time) * 1000),'h:m') : '' }} {{ Number(patientInfo.pump_record_info.pump_info.last_dose).toFixed(3) > 0 ?Number(patientInfo.pump_record_info.pump_info.last_dose).toFixed(3) : '--' }} <span>U</span>
                                        </div>
                                        <div class="remote-btn dose" @click="remoteDoseBox = !remoteDoseBox">
                                            远程大剂量
                                        </div>
                                    </div>
                                    <div class="row-2 flex justify-between align-center row mt-15">
                                        <div class="run-type">
                                            <div class="text">操作记录：</div>
                                        </div>
                                        <div class="remote-btn record" @click="getRemoteHistory(false)">
                                            <span class="flex align-center justify-center">操作记录<em class="badge ml-5 text-center" v-if="patientInfo.pump_record_info.pump_info.siis_record_count > 0">{{ patientInfo.pump_record_info.pump_info.siis_record_count }}</em></span>
                                        </div>
                                    </div>
                                </div>
                                <el-button style="border: unset; padding: unset; background: unset;"><i class="el-icon-s-tools planButton" :class="[patientInfo.pump_record_info.pump_info.siis_record_count > 0 ? '' : 'ml-5' ]"></i></el-button>
                            </el-tooltip>
                        </p>
                    </div>
                </div>
            </div>
            <div class="chart-line" v-if="allData.is_cgm == 1 ">
                <ChartsLine :value="allData.data" :target_low="allData.target_low" :target_high="allData.target_high" :specialArr="allData.life_record_coord" v-if="loading" />
            </div>
            <div class="baseLine" v-if="allData.is_cgm == 1 && allData.basal && allData.basal.length">
                <BaseLine :value="allData.basal" />
            </div>
            <div class="chart-line" v-if="allData.is_cgm == 0 ">
                <FinGetBlood :value="allData.data" :target_low="allData.target_high" :target_high="allData.target_low" />
            </div>
            <div class="scheme-module" v-if="lifeRecord && lifeRecord.length">
                <div class="data-card" v-for="(item, index) in lifeRecord" :key="index">
                    <template v-if="item.kind == 1 || item.kind == 4">
                        <div class="breakfast">
                            <div class="title">
                                <div class="breakfast-value" v-if="item.kind == 1">
                                    <p>{{item.record.food_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.food_time_str.split(' ')[1]}}</span></p>
                                    <h4>{{item.analysis.title ? item.analysis.title : ''}}</h4>
                                </div>
                                <div class="breakfast-value" v-else-if="item.kind == 2">
                                    <p>{{item.record.medicine_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.medicine_time_str.split(' ')[1]}}</span></p>
                                    <h4>{{item.record ? item.record.title : ''}}</h4>
                                </div>
                                <div class="breakfast-value" v-else-if="item.kind == 4">
                                    <p>{{item.record.ketone_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.ketone_time_str.split(' ')[1]}}</span></p>
                                    <h4>{{item.record ? item.record.title : ''}}</h4>
                                </div>
                                <div class="breakfast-value" v-else>
                                    <p>{{item.record.sports_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.sports_time_str.split(' ')[1]}}</span></p>
                                    <h4>{{item.record ? item.record.title : ''}}</h4>
                                </div>
                            </div>
                            <template v-if="item.record.detail">
                                <div class="food" :style="{'overflow-y' : item.record.detail.length > 3 ? 'scroll' : 'unset' }">
                                    <div class="food-list" v-for="(items,idx) in item.record.detail" :key="idx">
                                        <p>
                                            <img v-if="item.kind == 1" src="@/assets/images/icons/u.png" alt="">
                                            <img v-if="item.kind == 2 && item.record.type == 1" src="@/assets/images/icons/ci.png" alt="">
                                            <img v-if="item.kind == 2 && item.record.type == 2" src="@/assets/images/icons/ku.png" alt="">
                                            <img v-if="item.kind == 3" src="@/assets/images/icons/kal.png" alt="">
                                            <img v-if="item.kind == 4" src="@/assets/images/icons/urine.png" alt="">
                                            {{items.title}}
                                            <span>{{items.num}}{{items.unit}}</span>
                                        </p>
                                    </div>
                                </div>
                            </template>
                            <template v-if="item.medicine">
                                <div class="food" :style="{'overflow-y' : item.medicine.length > 3 ? 'scroll' : 'unset' }">
                                    <div class="food-list" style="display: block;" v-for="(items,idx) in item.medicine" :key="idx">
                                        <div class="flex justify-between">
                                            <p class="align-center">
                                                <img src="@/assets/images/icons/ku.png" v-if="items.type == 2" alt="">
                                                <img src="@/assets/images/icons/ci.png" v-if="items.type == 1" alt="">
                                                <el-tooltip class="item" effect="dark" :content="(items.title + (items.is_auto == 0 ? (items.is_pen == 1 ? ' - 胰岛素笔' : items.is_pen == 2 ? ' - 胰岛素泵' : '') +  (items.is_square == 0  ? '- 常规' : items.is_square == 1 ? '- 方波' : '') + (items.is_remote == 1 ? '- 远程' : '') : 'SIIS' ) )" placement="top" v-if="isIpad">
                                                    <el-button style="border: unset; padding: unset; background: unset;" class="hansHover">{{titleSplit(items.title)}}
                                                </el-button>
                                                </el-tooltip>
                                                <template v-else>
                                                    {{titleSplit(items.title)}}<br />
                                                </template>
                                            </p>
                                            <p>{{items.period}}</p>
                                        </div>
                                        <p class="ml-20 mt-5 pl-15" style="width: 200px;font-size:12px;" v-if="!isIpad">
                                            <template v-if="items.is_auto == 0">
                                                {{ items.is_pen == 1 ? '胰岛素笔' : items.is_pen == 2 ? '胰岛素泵' : '' }} 
                                                {{ items.is_square == 0  ? ( items.is_pen == 0 ? '常规' : '- 常规' ) : items.is_square == 1 ? items.is_pen == 0 ? '方波' : '- 方波' : ''  }} 
                                                {{ items.is_remote == 1 ? '- 远程' : '' }}
                                            </template>
                                            <template v-else>
                                                SIIS
                                            </template>
                                        </p>
                                    </div>
                                </div>
                            </template>
                            <div class="food-pics">
                                <div class="pics" v-for="(items, idx) in item.record.pics" @click="pushList(item.record.pics,idx)" :key="idx">
                                    <img :src="items" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="fluctuation">
                            <template v-if="allData.is_cgm == 1 && item.analysis != ''">
                                <div class="value">
                                    <div class="up-suger" :class="{'isHigh' : item.analysis.up_range_status == 'high'}">
                                        <p>升糖幅度</p>
                                        <h4>{{item.analysis.up_range}}</h4>
                                        <p>mmol/L</p>
                                    </div>
                                    <div class="fluctuation-value" :class="{'isHigh' : item.analysis.wave_range_status == 'high'}">
                                        <p>波动值</p>
                                        <h4>{{item.analysis.wave_range}}</h4>
                                        <p>mmol/L</p>
                                    </div>
                                </div>
                                <div class="intake">
                                    <div class="water">
                                        <div class="title">
                                            <img src="@/assets/images/icons/u.png" alt="">
                                            <p>{{ isIpad ? '碳水' : '碳水摄入' }}</p>
                                        </div>
                                        <p>
                                            {{item.record.carbohydrate}}
                                            <span v-if="item.record.carbohydrate != '--'">克</span>
                                        </p>
                                    </div>
                                    <div class="insulin">
                                        <div class="title">
                                            <img src="@/assets/images/icons/ku.png" alt="">
                                            <p>胰岛素</p>
                                        </div>
                                        <p>
                                            {{item.analysis.insulin_num}}
                                            <span v-if="item.analysis.insulin_num != '--'">U</span>
                                        </p>
                                    </div>
                                    <div class="oral-liquid">
                                        <div class="title">
                                            <img src="@/assets/images/icons/ci.png" alt="">
                                            <p>口服药</p>
                                        </div>
                                        <p>
                                            {{item.analysis.oral_medicine_num}}
                                            <span v-if="item.analysis.oral_medicine_num != '--'">次</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="chart-main">
                                    <ChartsLine :value="item.analysis.list" :target_low="item.user_info.target_low" :target_high="item.user_info.target_high" :type="false" :specialArr="item.analysis.life_record_coord" />
                                </div>
                            </template>
                            <template v-else>
                                <div class="center-box">
                                    <div class="notDate">
                                        <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                                        <img src="@/assets/images/error/dark_empty.png" v-else alt />
                                        <h3>暂无数据</h3>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="value-list">
                            <template v-if="allData.is_cgm == 1 && item.analysis != ''">
                                <div class="standard">
                                <div class="before-meal">
                                    <p>餐前血糖值</p>
                                    <em>{{item.analysis.before_time}}</em>
                                    <h4 :class="[ item.analysis.before_bs_status == 'high' ? 'isHigh' : ( item.analysis.before_bs_status == 'low' ? 'isLow' : 'normal' )]">{{item.analysis.before_bs}}</h4>
                                    <span>mmol/L</span>
                                </div>
                                <div class="after-meal">
                                    <p>餐后最高值</p>
                                    <em>{{item.analysis.highest_time}}</em>
                                    <h4 :class="[ item.analysis.highest_bs_status == 'high' ? 'isHigh' : ( item.analysis.highest_bs_status == 'low' ? 'isLow' : 'normal' )]">{{item.analysis.highest_bs}}</h4>
                                    <span>mmol/L</span>
                                </div>
                                <div class="meal-two">
                                    <p>餐二血糖值</p>
                                    <em>{{item.analysis.after_time}}</em>
                                    <h4 :class="[item.analysis.after_bs_status == 'high' ? 'isHigh' : ( item.analysis.after_bs_status == 'low' ? 'isLow' : 'normal' ) ]">{{item.analysis.after_bs}}</h4>
                                    <span>mmol/L</span>
                                </div>
                                </div>
                                <div class="recover" ref="offsetWidth">
                                <div class="before-meal">
                                    <p ref="centerLeft">恢复到餐前值</p>
                                    <span>{{item.analysis.before_bs}}<em>mmol/L</em></span>
                                    <h4 :class="[ item.analysis.resume_before_status == 'high' ? 'isHigh' : ( item.analysis.resume_before_status == 'low' ? 'isLow' : 'normal' )]">{{item.analysis.resume_before}}<span v-if="item.analysis.resume_before != '--'">分</span></h4>
                                </div>
                                <div class="after-meal">
                                    <p>{{ isIpad ? '恢复餐二' : '恢复餐二标准值' }}</p>
                                    <span>7.8<em>mmol/L</em></span>
                                    <h4 :class="[ item.analysis.resume_standard_status == 'high' ? 'isHigh' : ( item.analysis.resume_standard_status == 'low' ? 'isLow' : 'normal' )]">{{item.analysis.resume_standard}}<span v-if="item.analysis.resume_standard != '--'">分</span></h4>
                                </div>
                                <div class="meal-two">
                                    <p>食物吸收时间</p>
                                    <span>1.7<em>mmol/L</em></span>
                                    <h4>{{item.analysis.absorb_time}}<span v-if="item.analysis.absorb_time != '--'">分</span></h4>
                                </div>
                                </div>
                                <div class="remark">
                                    <div class="remark-text">
                                        <p>备注：<span :title="item.record.note">{{item.record.note.substring(0,50)}}{{item.record.note.length > 50 ? '...' : '' }}</span>
                                            <i class="el-icon-edit-outline" @click="item.editRemark = !item.editRemark"></i>
                                        </p>
                                        <div class="add-remark" v-if="item.editRemark">
                                            <div class="textarea-box">
                                                <textarea placeholder="请输入备注..." v-model="item.remarkValue"></textarea>
                                            </div>
                                            <div class="button">
                                                <el-button @click="item.editRemark = !item.editRemark">取消</el-button>
                                                <el-button class="submit" @click="submitRemark(item.record.id,index,item.remarkValue)">确定</el-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="center-box">
                                    <div class="notDate">
                                        <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                                        <img src="@/assets/images/error/dark_empty.png" v-else alt />
                                        <h3>暂无数据</h3>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="longPass">
                            <div class="title">
                                <div class="breakfast-value" v-if="item.kind == 1">
                                    <p>{{item.record.food_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.food_time_str.split(' ')[1]}}</span></p>
                                </div>
                                <div class="breakfast-value" v-else-if="item.kind == 2">
                                    <p>{{item.record.medicine_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.medicine_time_str.split(' ')[1]}}</span></p>
                                </div>
                                <div class="breakfast-value" v-else-if="item.kind == 3">
                                    <p>{{item.record.sports_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.sports_time_str.split(' ')[1]}}</span></p>
                                </div>
                                <div class="breakfast-value" v-else-if="item.kind == 4">
                                    <p>{{item.record.ketone_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.ketone_time_str.split(' ')[1]}}</span></p>
                                </div>
                                <div class="breakfast-value" v-else-if="item.kind == 5">
                                    <p>{{item.record.pure_finger_blood_time_str.split(' ')[0].replace('-','月')}}<span>{{item.record.pure_finger_blood_time_str.split(' ')[1]}}</span></p>
                                </div>
                            </div>
                            <template v-if="item.record.detail && item.kind < 4">
                                <div class="food" :style="{'overflow-y' : item.record.detail.length > 4 ? 'scroll' : 'unset' }">
                                    <div class="food-list" v-for="(items,idx) in item.record.detail" :key="idx">
                                        <p>
                                            <img v-if="item.kind == 1" src="@/assets/images/icons/u.png" alt="">
                                            <img v-if="item.kind == 2 && item.record.type == 1" src="@/assets/images/icons/ci.png" alt="">
                                            <img v-if="item.kind == 2 && item.record.type == 2" src="@/assets/images/icons/ku.png" alt="">
                                            <img v-if="item.kind == 2 && item.record.type == 3" src="@/assets/images/Patient/Details/GLP.png" alt="">
                                            <img v-if="item.kind == 3" src="@/assets/images/icons/kal.png" alt="">
                                            {{items.title}}
                                            <span>{{Number(items.num).toFixed(3)}} {{items.unit}}</span>
                                        </p>
                                    </div>
                                </div>
                            </template>
                            <template v-if="item.kind > 3">
                                <div class="food">
                                    <div class="food-list">
                                        <p>
                                            <img v-if="item.kind == 4" src="@/assets/images/icons/urine.png" alt="">
                                            <img v-if="item.kind == 5" src="@/assets/images/icons/blood.png" alt="">
                                            {{ item.record.title }} 
                                            <span v-if="item.kind == 5">&nbsp;{{item.record.brand_title}} {{Number(item.record.fb_val).toFixed(1)}} mmol/L</span>
                                        </p>
                                    </div>
                                </div>
                            </template>
                            <template v-if="item.medicine">
                                <div class="food" :style="{'overflow-y' : item.medicine.length > 4 ? 'scroll' : 'unset' }">
                                    <div class="food-list" v-for="(items,idx) in item.medicine" :key="idx">
                                        <p>
                                            <img src="@/assets/images/icons/ku.png" v-if="items.type == 2" alt="">
                                            <img src="@/assets/images/icons/ci.png" v-if="items.type == 1" alt="">
                                            {{titleSplit(items.title)}}
                                        </p>
                                        <p>{{items.period}}</p>
                                    </div>
                                </div>
                            </template>
                            <div class="remote ml-10 flex align-center">
                                <i class="el-icon-minus font-weight mr-5" v-if="item.record.is_auto > 0"></i>
                                <p>{{ item.record.is_auto == 1 ? 'SIIS' : '' }}</p>
                                <i class="el-icon-minus font-weight mr-5" v-if="item.record.is_pen > 0 && item.record.is_auto < 1"></i>
                                <p v-if="item.record.is_auto < 1">{{ item.record.is_pen == 1 ? '胰岛素笔' : item.record.is_pen == 2 ? '胰岛素泵' : '' }}</p>
                                <i class="el-icon-minus font-weight ml-5 mr-5" v-if="(item.record.is_square == 0 || item.record.is_square == 1) && item.record.is_auto < 1"></i>
                                <p v-if="item.record.is_auto < 1 ">{{ item.record.is_square == 0  ? '常规' : item.record.is_square == 1 ? '方波' : ''  }}</p>
                                <i class="el-icon-minus font-weight ml-5 mr-5" v-if="item.record.is_remote == 1 && item.record.is_auto < 1"></i>
                                <p v-if="item.record.is_auto < 1">{{ item.record.is_remote == 1 ? '远程' : '' }}</p>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="empty" style="padding:100px 0;" v-show="!lifeRecord||!lifeRecord.length">
                <div class="center">
                    <img src="@/assets/images/error/empty.png" v-if="!Dark" alt />
                    <img src="@/assets/images/error/dark_empty.png" v-else alt />
                    <p>暂无生活记录数据</p>
                </div>
            </div>
        </div>

        <el-dialog title="远程基础率" :visible.sync="remoteBasalDialog" width="32%" :before-close="handleClose" :modal-append-to-body="false">
            <div class="remoteBasalClass basal flex flex-wrap justify-center">
                <div class="time mt-20 flex" v-for="(item,index) in basalTimeList" :key="index">
                    <el-time-select class="hours" v-model="item.hour" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="选择时间" :clearable="false" @change="selectBasalTimes(index,item.hour)"></el-time-select>
                    <div class="flex justify-center align-center">
                        <p class="ml-20 pl-10 timePeriod text-center">{{ item.times }}</p>
                        <div class="ml-20 pl-10 flex justify-center align-center pion-rel">
                            <el-input-number v-model="item.insulin" :min="doseType == '丹纳RS' ? 0.04 : 0" :max="doseType == 'Dash' ? 4.95 : doseType == '丹纳RS' ? 3.3 : 5" :precision="3" :step="doseType == 'Dash' ? 0.050 : doseType == '丹纳R' || doseType == '丹纳RS' ? 0.010 : 0.025" step-strictly></el-input-number>
                            <span class="unit pion-abs">U</span>
                        </div>
                        <div class="nbsp ml-20" v-if="index == 0"></div>
                        <el-button class="ml-20 deleteBtn" v-if="index > 0" type="danger" icon="el-icon-delete" circle @click="deleteRemoteBasal(index)"></el-button>
                    </div>
                </div>
                <div class="add mt-20 pt-20">
                    <el-button @click="addBasal" class="addTime flex justify-center align-center"><i class="el-icon-error"></i>添加时间段</el-button>
                    <p>注：最小步长为{{ doseType == 'Dash' ? 0.050 :doseType == '丹纳R' || doseType == '丹纳RS' ? 0.010 : 0.025 }}，如果剂量非步长的倍数，程序会自动调整</p>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="remoteBtnC" @click="remoteBasalDialog = false">取 消</el-button>
                <el-button class="remoteBtnS" type="primary" @click="submitBasal">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="远程大剂量" :visible.sync="remoteDoseBox" width="30%" :before-close="remoteDoseBoxClose" :modal-append-to-body="false">
            <div class="remoteBasalClass flex justify-center align-center pion-rel" v-if="doseSource == 0">
                <el-input-number v-model="remoteDoseVal" :min="0" :precision="3" :step="doseType == 'Dash' ? 0.050 :doseType == '丹纳R' || doseType == '丹纳RS' ? 0.100 : 0.025"></el-input-number>
                <span class="unit pion-abs">U</span>
            </div>
            <div class="remoteBasalClass" v-else>
                <div class="litTabers flex justify-around">
                    <div class="pion-rel tabers" :class="[remoteTabNum == index ? 'on' : '']" v-for="(item,index) in remoteTab" :key="item" @click="remoteTabNum = index">{{ item }}</div>
                </div>
                <div class="tab1 flex justify-center align-center pion-rel mt-20 pt-20" v-if="remoteTabNum == 0">
                    <el-input-number v-model="remoteDoseVal" :min="0" :max="doseType == 'Dash' ? 30 :doseType == '丹纳R' || doseType == '丹纳RS' ? 40 : 25" :precision="3" :step="doseType == 'Dash' ? 0.050 :doseType == '丹纳R' || doseType == '丹纳RS' ? 0.100 : 0.025" step-strictly></el-input-number>
                    <span class="unit pion-abs">U</span>
                </div>
                <div class="tab2" v-if="remoteTabNum == 1">
                    <div class="insulin flex justify-center align-center pion-rel mt-20 pt-20">
                        <el-input-number v-model="remoteDoseFVal" :min="0" :max="doseType == 'Dash' ? 30 :doseType == '丹纳R' || doseType == '丹纳RS' ? 40 : 25" :precision="3" :step="doseType == 'Dash' ? 0.050 :doseType == '丹纳R' || doseType == '丹纳RS' ? 0.100 : 0.025" step-strictly></el-input-number>
                        <span class="unit pion-abs">U</span>
                    </div>
                    <p class="text-center mt-15 mb-15 pt-10 pb-10">方波持续时间</p>
                    <div class="times flex justify-center align-center pion-rel">
                        <el-input-number v-model="remoteDoseFTime" :min="doseType == '丹纳R' || doseType == '丹纳RS' ? 30 : 15" :max="480" :precision="0" :step="doseType == '丹纳R' || doseType == '丹纳RS' ? 30 : 15" step-strictly></el-input-number>
                        <span class="unit time pion-abs">分钟</span>
                    </div>
                </div>
                <div class="tab3" v-if="remoteTabNum == 2">
                    <div class="double flex justify-center mt-20 pt-20">
                        <div class="insulin pt-10 mr-10">
                            <p class="text-center mb-10">常规{{ percent }}%</p>
                            <div class="flex justify-center align-center pion-rel">
                                <el-input-number v-model="remoteDoseVal" :min="0" :max="doseType == 'Dash' ? 30 :doseType == '丹纳R' || doseType == '丹纳RS' ? 40 : 25" :precision="3" :step="doseType == 'Dash' ? 0.050 :doseType == '丹纳R' || doseType == '丹纳RS' ? 0.100 : 0.025" step-strictly></el-input-number>
                                <span class="unit pion-abs">U</span>
                            </div>
                        </div>
                        <div class="FInsulin pt-10 ml-10">
                            <p class="text-center mb-10">方波{{ FPercent }}%</p>
                            <div class="flex justify-center align-center pion-rel">
                                <el-input-number v-model="remoteDoseFVal" :min="0" :max="doseType == 'Dash' ? 30 :doseType == '丹纳R' || doseType == '丹纳RS' ? 40 : 25" :precision="3" :step="doseType == 'Dash' ? 0.050 :doseType == '丹纳R' || doseType == '丹纳RS' ? 0.100 : 0.025" step-strictly></el-input-number>
                                <span class="unit pion-abs">U</span>
                            </div>
                        </div>
                    </div>
                    <p class="text-center mt-15 mb-15 pt-10 pb-10">方波持续时间</p>
                    <div class="times flex justify-center align-center pion-rel">
                        <el-input-number v-model="remoteDoseFTime" :min="doseType == '丹纳R' || doseType == '丹纳RS' ? 30 : 15" :max="480" :precision="0" :step="doseType == '丹纳R' || doseType == '丹纳RS' ? 30 : 15" step-strictly></el-input-number>
                        <span class="unit time pion-abs">分钟</span>
                    </div>
                    <div class="sum flex  justify-center align-center mt-10 pt-20">
                        总量：<h4>{{ insulinSum }} <span>u</span></h4>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="remoteBtnC" @click="remoteDoseBox = false">取 消</el-button>
                <el-button class="remoteBtnS" type="primary" @click="submitRemoteDose">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="操作记录" :visible.sync="remoteHistory" width="32%" :before-close="remoteHistoryClose" :modal-append-to-body="false">
            <div class="remoteHistory">
                <div class="lists flex align-center justify-between solid-bottom pb-10" :class="[index > 0 ? 'mt-20' : '' ]" v-for="(item,index) in remoteHistoryList" :key="index">
                    <h4 class="title">
                        {{ Number(item.type) == 1 ? '暂停胰岛素泵 ' : Number(item.type) == 2 ? '恢复胰岛素泵 ' : Number(item.type) == 3 ? '设置基础率' : Number(item.type) == 4 ? '设置临时基础率' : Number(item.type) == 5 ? '停止临时基础率' : Number(item.type) == 6 ? '注射大剂量' : '未知错误' }}
                        <el-tooltip placement="top" popper-class="pt-12 remote-button" v-if="Number(item.type) == 6">
                            <div slot="content" class="insulin flex">
                                <div class="insulinInfo flex">
                                    <p class="font-12" v-if="item.normal_num != 0">{{ item.normal_num }} U（常规）</p>
                                    <p class="font-12" v-if="item.square_num != 0">{{ item.square_num }} U（方波）</p>
                                </div>
                            </div>
                            <el-button style="border: unset; padding: unset; background: unset;"><i class="el-icon-warning planButton"></i></el-button>
                        </el-tooltip>
                    </h4>

                    <div class="addTime flex">
                        <h4 class="titles font-12">添加时间：</h4>
                        <div class="infos font-12">{{ item.create_time }}</div>
                    </div>
                    <div class="result flex">
                        <h4 class="titles font-12">执行状态：</h4>
                        <div class="infos font-12" :class="[item.status == 1 ? 'high' : item.status == 3 ? 'high' : item.status == 5 ? 'normal' : item.status == -1 ? 'low' : item.status == -3 ? 'low' : '']">{{ item.status == 1 ? '等待执行' : item.status == 3 ? '已下发' : item.status == 5 ? '执行成功' : item.status == -1 ? '执行失败' : item.status == -3 ? '已取消' : '出现未知错误，未返回执行结果' }}</div>
                    </div>
                </div>
                <div class="loadingMore flex justify-center mt-20">
                    <el-button class="remoteBtnS" :loading="loadMore" @click="loadingMore">{{ !loadMore ? '加载更多' : '加载中' }}</el-button>
                </div>
            </div>
        </el-dialog>

        <el-drawer class="shadow carousel pion-fixed" :size="'70%'" title="" :modal-append-to-body="false" :append-to-body="false" :visible.sync="showPicList" :with-header="false" v-if="showPicList">
            <el-carousel :autoplay="false" indicator-position="outside" :arrow="isIpad ? 'always' : 'hover' " :initial-index="picIndex">
                <el-carousel-item v-for="item in carouselList" :key="item">
                    <img :src="item" alt="">
                </el-carousel-item>
            </el-carousel>
        </el-drawer>
    </div>
</template>

<script>
    import { time } from '_js/Cache'
    import { Process } from '../Model/index';
    import { ChartsLine, FinGetBlood, BaseLine } from '_c/Charts/index';
    import { addNote, remoteSetting, remoteHistory, remoteSwitchLoop } from 'quest';
    export default {
        props:{
            allData: Object,
            lifeRecord: Object,
            patientInfo: Object,
            Dark: Boolean
        },

        watch:{
            today(val){
                this.$emit('dateChange',val);
            },
            allData:{
                handler(val){
                    if(val != null && val != 'null'){
                        this.hasAllData = true;
                    }
                },
                deep: true
            },
            remoteTabNum:{
                handler(){    
                    this.remoteDoseVal = '',
                    this.remoteDoseFVal = '',
                    this.remoteDoseFTime = this.doseType == '丹纳R' || this.doseType == '丹纳RS' ? 30 : 15;
                },
                deep: true,
            },
            lifeRecord:{
                handler(val){
                    if(val != null && val != 'null'){
                        this.hasLifeData = true;
                        this.addStr(true);
                    }else{
                        this.loading = false
                    }
                },
                deep: true
            },
            patientInfo:{
                handler(val){
                    let type = val.pump_record_info.pump_info.source;
                    let unit = val.pump_record_info.pump_info.bolus_switch;
                    console.log(type);
                    this.doseType = type == '1' ? '糖脉' : type == '2' ? 'Dash' : type == '3' ? '丹纳R' : type == '4' ? '丹纳RS' : '';
                    this.doseSource = unit;
                    this.loopType = val.siis == 1 ? true : false;
                    let data = JSON.parse(val.pump_record_info.profile_info.basal);
                    this.basalTimeList = this.addTimesToData(data);
                },
                deep: true
            },
            remoteDoseVal:{
                handler(val){
                    this.percent = this.getPercentage(val,[val,this.remoteDoseFVal]);
                    this.FPercent = this.getPercentage(this.remoteDoseFVal,[val,this.remoteDoseFVal]);
                    this.insulinSum = Number(val + this.remoteDoseFVal).toFixed(3);
                },
                deep: true
            },
            remoteDoseFVal:{
                handler(val){
                    this.FPercent = this.getPercentage(val,[val,this.remoteDoseVal]);
                    this.percent = this.getPercentage(this.remoteDoseVal,[val,this.remoteDoseVal]);
                    this.insulinSum = Number(val + this.remoteDoseVal).toFixed(3);
                },
                deep: true
            }
        },
        components:{
            Process,
            ChartsLine,
            BaseLine,
            FinGetBlood
        },

        data(){
            return{
                today: '',
                selectDate: '',
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    }
                },
                empty: "",
                loading: false,
                hasAllData: false,
                hasLifeData: false,
                date_time: new Date().getFullYear() + '-' + ( parseInt(new Date().getMonth()) + 1 < 10 ? '0' + ( parseInt(new Date().getMonth() ) + 1 ) : parseInt(new Date().getMonth()) + 1 )  + '-' + (new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate() ),
                carouselList: [],
                picIndex: 0,
                showPicList: false,
                isIpad: false,
                loopType: false,
                remoteBasalDialog: false,
                doseType: '糖脉',
                doseSource: '0',
                remoteBasalType: false,
                remoteDoseBox: false,
                remoteDoseVal: '',
                remoteDoseFVal: '',
                remoteTab: ['常规大剂量','方波大剂量','双波大剂量'],
                remoteTabNum: 0,
                remoteDoseFTime: 0,
                percent: 0,
                FPercent: 0,
                insulinSum: 0,
                basalTimeList:[
                    {
                        hour: '00:00',
                        times: '00:00 - 00:30',
                        insulin: ''
                    },
                    {
                        hour: '00:30',
                        times: '00:30 - 01:00',
                        insulin: ''
                    },
                    {
                        hour: '09:00',
                        times: '09:00 - 09:30',
                        insulin: ''
                    },
                    {
                        hour: '15:00',
                        times: '15:00 - 15:30',
                        insulin: ''
                    },
                    {
                        hour: '19:00',
                        times: '19:00 - 19:30',
                        insulin: ''
                    },
                    {
                        hour: '21:00',
                        times: '21:00 - 00:30',
                        insulin: ''
                    },
                    {
                        hour: '20:00',
                        times: '21:00 - 00:30',
                        insulin: ''
                    }
                ],
                remoteHistory: false,
                getRemoteHistoryData:{
                    uid: this.$route.query.id,
                    page: 1,
                    count: 9
                },
                remoteHistoryList:[],
                loadMore: false,
            }
        },
        filters: {
            empty(value) {
                if (!value && value != 0) {
                    return "--";
                } else {
                    return value;
                }
            },
            tofixed(num) {
                return Math.floor(num);
            }
        },
        methods:{
            changeDateByIcon(step) {
                let self = this,
                    date = this.today,
                    today = time(new Date(),'YYYY-MM-DD');
                //加一天
                function addDate(time) {
                    //加一天
                    var timestamp = Date.parse(new Date(time));
                    timestamp = timestamp / 1000;
                    today = new Date(today) / 1000;
                    if( today > timestamp ){
                        self.loading = false;
                        timestamp += 86400; //加一天
                        var newTime = new Date(timestamp * 1000).format("yyyy-MM-dd");
                        return newTime;
                    }else{
                        return time
                    }
                }

                //减一天
                function delDate(time) {
                    self.loading = false;
                    var timestamp = Date.parse(new Date(time));
                    timestamp = timestamp / 1000;
                    timestamp -= 86400; //加一天
                    var newTime = new Date(timestamp * 1000).format("yyyy-MM-dd");

                    return newTime;
                }

                //日期格式
                Date.prototype.format = function(format) {
                    var date = {
                        "M+": this.getMonth() + 1,
                        "d+": this.getDate(),
                        "h+": this.getHours(),
                        "m+": this.getMinutes(),
                        "s+": this.getSeconds(),
                        "q+": Math.floor((this.getMonth() + 3) / 3),
                        "S+": this.getMilliseconds()
                    };
                    if (/(y+)/i.test(format)) {
                        format = format.replace(
                            RegExp.$1,
                            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
                        );
                    }
                    for (var k in date) {
                        if (new RegExp("(" + k + ")").test(format)) {
                            format = format.replace(
                            RegExp.$1,
                            RegExp.$1.length == 1
                                ? date[k]
                                : ("00" + date[k]).substr(("" + date[k]).length)
                            );
                        }
                    }
                    return format;
                };
                if (step > 0) {
                    this.today = addDate(date);
                } else {
                    this.today = delDate(date);
                }
            },

            selectDay(val){
                this.loading = false;
                if(val != 'null' && val != null){
                    this.today = time(val,'YYYY-MM-DD');
                }else{
                    this.today =  time(new Date(),'YYYY-MM-DD')
                }
                
            },

            addStr(boole){
                let timer = setInterval( () =>{
                    if(this.hasAllData && boole){
                        this.loading = true;
                    }
                    clearInterval(timer);
                },100)
            },
            getRemoteTimes(t,tp){
                return time(t,tp);
            },
            submitRemark(id,index,note){
                const data = {
                    id,
                    type: 1,
                    note
                };
                if(!note){
                    this.$message.warning("请输入备注");
                }else{
                    addNote(data).then(res => {
                        if (res.data.code == 100000) {
                            this.$message.success('提交成功！');
                            this.$emit('addNote',{index,note})
                        } else {
                            this.$message.error('提交失败！请重试');
                        }
                    })
                }

            },
            pushList(list,idx){
                this.carouselList = list;
                this.picIndex = idx;
                this.showPicList = true;
            },
            titleSplit(title){
                return this.isIpad ? (title.split(' ')[0].length > 5 ? title.split(' ')[0].substring(0,7) + '... ' + ( title.split(' ')[1].length > 3 ? title.split(' ')[1].substring(0,6) : title.split(' ')[1] ) : title) : title;
            },
            settingRunType(){
                let type = this.patientInfo.pump_record_info.pump_info.work_status == 1 ? 1 : 2;
                let uid = this.$route.query.id;
                remoteSetting({type, uid}).then( res =>{
                    if (res.data.code == 100000) {
                        this.$message.success('操作成功！');
                        let pump_record_info = res.data.data;
                        this.$emit('upDataPumpRecordInfo',pump_record_info);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            handleClose(){
                this.remoteBasalDialog = false;
            },
            remoteDoseBoxClose(){
                this.remoteDoseBox = false;
            },
            remoteHistoryClose(){
                this.remoteHistory = false;
            },
            submitRemoteDose(){
                let data,
                    canSubmit = true;
                if(this.remoteTabNum == 1){
                    data = {
                        uid: this.$route.query.id,
                        type: 6,
                        square_num: this.remoteDoseFVal,
                        square_duration: this.remoteDoseFTime * 60,
                    }
                    if(data.square_num > this.patientInfo.pump_record_info.pump_info.max_bolus){
                        this.$message.error('最大值不能超过' + this.patientInfo.pump_record_info.pump_info.max_bolus + 'U');
                        canSubmit = false
                    }else{
                        canSubmit = true
                    }
                }else if(this.remoteTabNum == 2){
                    data = {
                        uid: this.$route.query.id,
                        type: 6,
                        normal_num: this.remoteDoseVal,
                        square_num: this.remoteDoseFVal,
                        square_duration: this.remoteDoseFTime * 60
                    }
                    if((data.normal_num + data.square_num) > this.patientInfo.pump_record_info.pump_info.max_bolus){
                        this.$message.error('最大值不能超过' + this.patientInfo.pump_record_info.pump_info.max_bolus + 'U');
                        canSubmit = false
                    }else{
                        canSubmit = true
                    }
                }else{
                    data = {
                        uid: this.$route.query.id,
                        type: 6,
                        normal_num: this.remoteDoseVal
                    }
                    if( data.normal_num > this.patientInfo.pump_record_info.pump_info.max_bolus){
                        this.$message.error('最大值不能超过' + this.patientInfo.pump_record_info.pump_info.max_bolus + 'U');
                        canSubmit = false
                    }else{
                        canSubmit = true
                    }
                }
                if(canSubmit){
                    remoteSetting(data).then( res =>{
                        if (res.data.code == 100000) {
                            this.$message.success('操作成功！');
                            this.remoteDoseBox = false;
                            this.$emit('upDataPumpRecordInfo',res.data.data);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                }

            },
            getPercentage(part, values) {
                const total = values.reduce((sum, num) => sum + num, 0); // 计算总数
                return total === 0 ? 0 : Math.round((part / total) * 100); // 避免除以0
            },
            addBasal(){
                this.basalTimeList.push({
                    hour: '',
                    times: '00:00-00:30',
                    insulin: ''
                })
            },
            deleteRemoteBasal(index){
                this.basalTimeList.splice(index,1);

                const parseTime = (time) => {
                    const [h, m] = time.split(':').map(Number);
                    return h * 60 + m;
                };

                const formatTime = (minutes) => {
                    const h = String(Math.floor(minutes / 60)).padStart(2, '0');
                    const m = String(minutes % 60).padStart(2, '0');
                    return `${h}:${m}`;
                };
                            // 重新排序和更新 times
                this.basalTimeList = this.basalTimeList
                    .filter(item => item.hour)
                    .sort((a, b) => parseTime(a.hour) - parseTime(b.hour));

                // 重新计算 times
                for (let i = 0; i < this.basalTimeList.length; i++) {
                    const start = this.basalTimeList[i].hour;
                    let end = '23:59'; // 默认结束时间为23:59

                    // 如果不是最后一个元素，计算下一个时间段的结束时间
                    if (i < this.basalTimeList.length - 1) {
                        const nextStart = this.basalTimeList[i + 1].hour;
                        end = nextStart;
                    }

                    // 格式化结束时间
                    end = formatTime(parseTime(end));

                    // 设置 times 字段
                    this.basalTimeList[i].times = `${start} - ${end}`;
                }
            },
            selectBasalTimes(index, newHour) {
                const parseTime = (time) => {
                    const [h, m] = time.split(':').map(Number);
                    return h * 60 + m;
                };

                // 检查是否已存在相同的时间（排除当前索引）
                const isDuplicate = this.basalTimeList.some((item, i) => i !== index && item.hour === newHour);
                if (isDuplicate) {
                    this.$message.warning(`时间段 ${newHour} 已存在，请选择其他时间！`);
                    this.basalTimeList[index].hour = '';
                    return;
                }

                // 更新选中的 hour
                this.basalTimeList[index].hour = newHour;

                // 过滤无效数据并排序
                this.basalTimeList = this.basalTimeList
                    .filter(item => item.hour)
                    .sort((a, b) => parseTime(a.hour) - parseTime(b.hour));

                // 重新计算 times
                for (let i = 0; i < this.basalTimeList.length; i++) {
                    const start = this.basalTimeList[i].hour;
                    let end = '23:59'; // 默认结束时间为23:59

                    // 如果不是最后一个元素，计算下一个时间段的结束时间
                    if (i < this.basalTimeList.length - 1) {
                        const nextStart = this.basalTimeList[i + 1].hour;
                        end = nextStart;
                    }

                    this.basalTimeList[i].times = `${start} - ${end}`;
                }
            },
            submitBasal(){
                let data = {
                    uid: this.$route.query.id,
                    type: 3,
                    basal: JSON.stringify(
                        this.basalTimeList.map(item => {
                            // eslint-disable-next-line no-unused-vars
                            const { times, ...rest } = item; // 解构去除 times
                        return rest;
                    }))
                },
                canSubmit = false;
                this.basalTimeList.map( item =>{
                    if(item.hour == '' || item.insulin == '' || item.insulin == 'NaN'){
                        canSubmit = true;
                    }
                })
                if(canSubmit == true){
                    this.$message.error('请完整填写相关信息！');
                }else{
                    remoteSetting(data).then( res =>{
                        if (res.data.code == 100000) {
                            this.$message.success('操作成功！');
                            this.remoteBasalDialog = false;
                            this.$emit('upDataPumpRecordInfo',res.data.data);
                        } else {
                            this.$message.error(res.data.msg);
                            this.remoteBasalDialog = false;
                        }
                    })
                }
            },
            getRemoteHistory(type){
                remoteHistory(this.getRemoteHistoryData).then( res =>{
                    if (res.data.code == 100000) {
                        if(this.getRemoteHistoryData.page > 1){
                            res.data.data.map(item =>{
                                this.remoteHistoryList.push(item);
                            })
                            this.loadMore = false;
                        }else{
                            this.remoteHistoryList = res.data.data;
                        }

                        if(!type){
                            this.remoteHistory = !this.remoteHistory;
                        }
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            loadingMore(){
                this.loadMore = true;
                this.getRemoteHistoryData.page +=1;
                this.getRemoteHistory(true)
            },
            addTimesToData(data) {
                return data.map((item, index) => {
                    // 获取下一个元素的 hour（如果是最后一个元素，结束时间为23:59）
                    let endHour = index < data.length - 1 ? data[index + 1].hour : '23:59';

                    // 为每个元素新增 times 字段
                    item.times = `${item.hour} - ${endHour}`;

                    return item;
                });
            },
            switchLoop(val){
                let data = {
                    uid: this.$route.query.id,
                    siis: val == true ? 1 : 0
                }
                remoteSwitchLoop(data).then( res =>{
                    if (res.data.code == 100000) {
                        this.$message.success('操作成功！');
                        this.loopType = true;
                    } else {
                        this.$message.error(res.data.msg);
                        this.loopType = false;
                    }
                })
            }
        },
        created(){
            // this.patientInfo.pump_record_info.pump_info.is_remote = 1;
            this.today = time(new Date(),'YYYY-MM-DD');
        },
        mounted(){
            this.isIpad = window.innerWidth >= 1024 && window.innerWidth <= 1366;
        }
    }
</script>

<style scoped lang="less">
    .rotate45 {
        transform: rotate(45deg);
    }

    .rotate90 {
        transform: rotate(90deg);
    }

    .rotate135 {
        transform: rotate(135deg);
    }

    .rotate180 {
        transform: rotate(180deg);
    }

    .size {
        width: 100%;
        height: 100%;
    }

    .shadow.carousel{
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 999999;
        /deep/ .rtl{
            background: none;
            right: 15%;
            box-shadow: none;

            .el-drawer__body{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                i{
                    background-color: #c1c1c1;
                    padding: 10px;
                    border-radius: 50px;
                    color: #0c0c0c;
                }
                .el-carousel{
                    width: 100%;
                    height: 100%;
                    .el-carousel__container{
                        height: 100% !important;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: scale-down;
                        }
                    }
                }
            }
        }
    }

    .everyDay{
        .date{
            height: 80px;
            background: #f7f7f7;
            transition: .3s;

            .info{
                display: flex;
                align-items: center;
                left: 15px;
                width: 300px;

                &>img{
                    width: 50px;
                    height: 50px;
                }

                .center-box{
                    width: unset !important;
                    height: unset !important;
                    display: block !important;
                    min-height: unset !important;
                    padding-left: 15px;
                }

                .name{
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                }

                .number{
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                }

            }

            .select{
                font-size: 20px;
                font-family: PingFangSC-Medium,PingFang SC;
                color: #666;

                i{
                    cursor: pointer;
                }
            }

            .dateSelect {
                right: 20px;
                width: 280px;
                height: 32px;
            }

        }
        .dayDate{
            .isCgm{
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                height: 150px;
                padding-left: 50px;
                margin-top: 20px;
                .item {
                    height: 100%;
                }
                .xuetangzhi {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .title {
                        font-size: 22px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #1f2e4d;
                        line-height: 22px;
                        .normal{
                            color: var(--defalutColor);
                        }
                        .high{
                            color: var(--bloodSugarHigh);
                        }
                        .low{
                            color: var(--bloodSugarLow);
                        }
                    }

                    .number {
                        font-size: 56px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #1f2e4d;
                        line-height: 28px;
                        align-items: baseline;
                        min-width: 0%;

                        p{
                            margin-top: 20px;
                        }

                        div{

                            i{
                                font-size: 45px;
                            }

                            span{
                                display: block;
                                font-size: 14px;
                                margin-top: -10px;
                            }
                        }

                    }

                    .average{
                        color: #515a6e;
                    }

                    .status {
                        width: 83px;
                        height: 33px;
                        background: #44d7b6;
                        border-radius: 8px;
                        font-size: 17px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #ffffff;
                        line-height: 33px;
                        text-align: center;
                    }

                    .status.height {
                        background: #ffe452;
                    }

                    .status.low {
                        background: #ff6e6e;
                    }
                }

                .process {
                    margin-left: 20px;
                }

                .indicator {
                    .indicator-item{
                        width: 100%;
                        span{
                            em{
                                font-style: normal;
                            }
                        }
                    }

                    .main-con {
                        font-size: 16px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 200;
                        color: #242424;
                        line-height: 30px;
                        display: flex;

                        span:nth-of-type(2) {
                            display: inline-block;
                            margin-left: 10px;
                        }
                    }

                    .main-con.text{
                        width: 100%;
                        flex-shrink: 0;
                    }

                    .info {
                        font-size: 11px;
                        font-family: PingFang-SC-Regular, PingFang-SC;
                        font-weight: 400;
                        color: #9f9f9f;
                        line-height: 15px;
                    }
                }
                .indicator-detail {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .indicator-item {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-around;
                    }

                    .row-list{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        img{
                            width: 25px;
                            height: 25px;
                        }

                        p{
                            width: 150px;
                            text-align: left;
                            margin-left: 15px;
                            display: flex;
                            font-size:16px;
                            font-weight: 500;

                            span{
                                margin-left: 25px;

                                i{
                                    font-style: normal;
                                }

                                em{
                                    font-style: normal;
                                    font-size:12px;
                                }
                            }
                        }
                    }

                    .img {
                        width: 18px;
                        height: 18px;

                        img {
                            .size;
                        }
                    }

                    .main-con {
                        font-size: 15px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #aaaaaa;
                        line-height: 23px;
                        margin-left: 10px;
                        margin-right: 50px;
                        position: relative;
                        top: -4px;
                    }

                    .info {
                        font-size: 13px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #242424;
                        line-height: 18px;
                    }
                }
            }

            .doctor_order{
                display: flex;
                align-items: center;
                margin-top: 50px;

                .doctor_info{
                    width: 88px;
                    text-align: center;

                    img{
                        width: 37px;
                        height: 37px;
                    }

                    p{
                        font-size: 13px;
                        color: #9A9A9A;
                    }
                }

                .order{
                    width: ~'calc(100% - 100px)';
                    margin-left: auto;
                    margin-right: 15px;
                    padding: 20px;
                    border: 1px solid #EFEFEF;
                }
            }

            .pumpInfo{
                .background{
                    background-color: #f7f7f7;
                    .vlow{
                        span{
                            color: var(--bloodSugarLow);
                        }
                    }
                    .low{
                        span{
                            color: var(--bloodSugarHigh);
                        }
                    }
                    .normal{
                        span{
                            color: var(--defalutColor);
                        }
                    }
                }
            }

            .chart-line {
                width: 100%;
                height: 230px;

                .ChartsLine,
                .charts {
                    width: 100%;
                    height: 230px;
                    & > *{
                        width: 100%;
                        height: 100%;
                    }

                    div {
                        height: 100%;
                    }
                }
            }
            .baseLine{
                width: 100%;
                height: 180px;
                margin-top: 20px;
            }

            .not_cgm{

                .tip_data{
                    display: flex;
                    width: 100%;
                    padding: 0px 80px;
                    justify-content: space-between;
                    margin-top: 55px;

                    .tip_left{
                        margin-top: 10px;
                        width: ~'calc(100% - 300px)';
                        display: flex;
                        justify-content: space-between;

                        .date_list{
                            text-align: center;

                            h3{
                                font-size: 22px;
                                color: #1F2E4D;
                            }

                            p{
                                font-size: 14px;
                                color: #9F9F9F;
                            }

                            h1{
                                font-size: 55px;
                                color: #1f2e4d;
                                margin-top: -15px;
                            }
                        }
                    }
                    .tip_right{

                        .list{
                            display: flex;
                            width: 180px;
                            align-items: center;

                        img{
                            width: 22px;
                            height: 22px;
                        }

                        p{
                            font-size: 20px;
                            color: #AAAAAA;
                            margin-left: 10px;
                            min-width: 60px;
                            display: flex;
                            justify-content: space-between;
                        }

                        h4{
                            font-size: 17px;
                            color:#242424;
                            margin-left: 15px;
                        }
                    }

                    .list:nth-child(n+2){
                        margin-top: 14px;
                    }
                }
                }
            }
        }

        .scheme-module{
            width: 100%;

            .scheme-title {
                width: 100%;
                margin-top: 20px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            .data-card {
                width: 100%;
                background: #ffffff;
                border-radius: 1px;
                border: 1px solid #e6e9f0;
                margin-top: 20px;
                padding: 10px 0;
                display: flex;

                &>div{
                    min-width: 33.3333333333%;
                }

                .breakfast{
                    padding: 15px 20px;
                    position: relative;

                    .title{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0px 10px;
                        margin-bottom: 18px;

                        .breakfast-value{
                            font-size: 19px;
                            p{
                                width: 140px;
                            }
                            span{
                                margin-left: 5px;
                            }

                            h4{
                                font-size: 20px;
                                margin-top: 5px;
                            }
                        }
                    }
                    .food{
                        max-height: 245px;

                        .food-list{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 10px;
                            background: #f9f9f9;
                            border-radius: 4px;
                            padding: 10px 10px;

                            p{
                                display: flex;
                                // align-items: center;
                                font-size: 15px;

                                img{
                                    width: 25px;
                                    height: 25px;
                                    margin-right: 10px;
                                }
                                span{
                                    display: block;
                                    width: 80px;
                                    text-align: right;
                                }
                            }
                            p:nth-of-type(2){
                                color: #aaa;
                            }
                        }
                    }
                    .food-pics{
                        display: flex;
                        align-items: center;
                        margin-top: 10px;

                        .pics{
                            width: 75px;
                            height: 75px;
                            overflow: hidden;
                            margin: 10px 5px;

                            img{
                                width: 100%;
                                height: 100%;
                                display: block;
                                border-radius: 5px;
                            }
                        }
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        top:0px;
                        right: 0px;
                        width:1px;
                        height:100%;
                        background: #e8e8e8;
                    }
                }

                .fluctuation{
                    padding: 15px 20px;
                    position: relative;

                    .value{
                        display:flex;
                        .up-suger,
                        .fluctuation-value{

                            width: 80px;
                            height: 80px;
                            border-radius: 8px;
                            text-align: center;
                            padding: 5px 0px;
                            box-sizing: border-box;
                            background: #26c3a8;
                            color: white;

                            p{
                                font-size: 12px;
                            }

                            h4{
                                font-size:28px;
                            }

                            p:nth-of-type(2){
                                font-size: 15px;
                                transform: scale(0.8);
                                margin-top: -8px;
                            }
                        }

                        .fluctuation-value{
                            margin-left: 15px;
                        }

                        .text{
                            margin-left: auto;
                        }

                        .isHigh{
                            background: var(--bloodSugarHigh);
                        }
                        .isLow{
                            background: var(--bloodSugarLow);
                        }
                        .normal{
                            background: var(--defalutColor);
                        }
                    }

                    .intake{
                        display: flex;
                        justify-content: space-between;
                        margin: 20px 0px;
                        margin-bottom: 10px;
                        font-size: 12px;
                        padding: 10px auto;

                        .title{
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img{
                                width: 26px;
                                height: 26px;
                            }

                            p{
                                margin-left: 5px;
                                font-size: 14px;
                            }
                        }
                        &>div{
                            flex: 1;
                            position: relative;
                            text-align: center;
                            padding: 10px 0px;

                            >p{
                                font-size: 38px;
                                color: #000;
                                font-weight: bold;

                                span{
                                    font-weight: normal;
                                }
                            }
                        }
                        .water,
                        .insulin{

                            &::before{
                                content: '';
                                position:absolute;
                                width:1px;
                                height: 100%;
                                right:0px;
                                top:0px;
                                background:#f7f7f7;
                            }
                        }
                        span{
                            font-size: 12px;
                            transform: scale(0.5);
                            color:#c2c2c2;
                            font-weight: unset;
                        }
                    }

                    .chart-main{
                        height: 158px;

                        .ChartsLine{
                            height: 158px;
                        }
                    }

                    &::after{
                        content: '';
                        position: absolute;
                        top:0px;
                        right: 0px;
                        width:1px;
                        height:100%;
                        background: #e8e8e8;
                    }
                }
                .value-list{

                    .standard,
                    .recover,
                    .remark{
                        display: flex;
                        padding: 25px 20px;

                        &>div{
                            flex: 1;
                            text-align: center;
                        }

                        .remark-text{
                            display: flex;
                            text-align: left;
                            transition: .3s;
                            margin-left: 20px;

                            i{
                                font-size: 22px;
                                color:#8d92a3;
                                cursor: pointer;
                                transition: .3s;
                                margin-top: -5px;

                                &:hover{
                                    color: #13be9b;
                                    transition: .3s;
                                }
                            }
                        }

                        p{
                            display: inline-block;
                            font-size: 13px;
                            color: #000;
                        }

                        em{
                            display:block;
                            font-size: 13px;
                            color: #aeaeaes;
                            font-style: normal;
                            color: #aeaeae;
                        }

                        h4{
                            font-size: 32px;
                            font-weight: bolder;
                        }

                        span{
                            font-size: 15px;
                            color: #aeaeae;
                        }
                    }
                    .standard{

                        h4{
                            color: #26C3A8;
                        }

                        .isHigh{
                            color: var(--bloodSugarHigh) !important;
                        }

                        .isLow{
                            color: var(--bloodSugarLow) !important;
                        }

                        .normal{
                            color: var(--defalutColor) !important;
                        }
                    }

                    .recover{

                        .before-meal,
                        .after-meal,
                        .meal-two{

                            h4{
                                color: #242424;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                span{
                                    display: block;
                                    margin-top: 15px;
                                    margin-left: 5px;
                                    font-weight: normal;
                                    font-size: 19px;
                                }
                            }

                            &>span{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 13px;
                                color: #484848;

                                em{
                                    color: #484848;
                                }
                            }
                        }

                        .isHigh{
                            color: var(--bloodSugarHigh) !important;
                        }

                        .isLow{
                            color: var(--bloodSugarLow) !important;
                        }

                        .normal{
                            color: var(--defalutColor) !important;
                        }
                    }
                    
                    .remark{
                        position: relative;

                        .remark-text{

                            p{
                                font-size: 15px;
                                color: #aeaeae;

                                span{
                                    color: #232323;
                                }
                            }
                        }

                        .add-remark{
                            position: absolute;
                            top: -220px;
                            left: -315px;
                            background: white;
                            box-shadow: 0px 0px 5px #e3e3e3;
                            width:705px;
                            height: 235px;
                            padding: 20px;

                            .textarea-box{
                                width: 660px;
                                height: 145px;
                                border: 1.5px solid #e3e3e3;
                                padding: 10px 15px;

                                textarea{
                                    width: 100%;
                                    height: 100%;
                                    border: none;
                                    resize: none;
                                    outline: none;
                                }
                            }

                            .button{
                                display: flex;
                                justify-content: flex-end;
                                margin-top: 15px;

                                button{
                                    width:100px;
                                    height:35px;
                                    border-radius: 5px;
                                }

                                .submit{
                                    background: #13be9b;
                                    color: white;
                                    margin-left: 15px;
                                }
                            }
                        }
                    }
                }

                .center-box{
                    width: 100%;
                    height: 100%;
                    min-height: 300px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        max-width: 106px;
                    }

                    h3{
                        text-align: center;
                        font-size: 18px;
                    }
                }

                .notInfo{
                    width: 235px;
                }

                .item {
                    flex: 1;
                    padding: 0 20px;
                    border-right: 1px solid #ddd;
                }

                .card-accounting {
                    border-right: 0px solid #ddd;
                }

                .header {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 600;
                    color: #242424;

                    .unit {
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                    }
                }

                .content {
                    width: 100%;
                    margin-top: 10px;

                    .food-card-box {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        height: 170px;

                        .item-box {
                            width: 28%;
                            height: 45px;
                            text-align: center;
                            background: #13be9b;
                            margin-right: 14px;
                            padding: 5px;
                            margin-bottom: 10px;
                            border-radius: 4px;
                            color: #fff;
                            position: relative;

                            .tip {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                right: 0px;
                                top: 0px;
                                z-index: 2;
                            }

                            p {
                                font-size: 12px;
                                max-width: 80%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                margin: 0 auto;
                            }
                        }
                    }

                    .food-plus-box {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        height: 170px;
                        overflow-y: auto;

                        .item-box {
                            width: 28%;
                            height: 45px;
                            text-align: center;
                            background: #fff;
                            border: 1px solid #ccc;
                            margin-right: 14px;
                            padding: 5px;
                            margin-bottom: 10px;
                            border-radius: 4px;
                            position: relative;

                            .tip {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                right: 0px;
                                top: 0px;
                                z-index: 2;
                            }

                            .icon {
                                display: block;
                                font-size: 15px;
                                font-weight: 600;
                                cursor: pointer;
                                margin: 10px auto;
                            }

                            p {
                                font-size: 12px;
                                margin: 0 auto;
                                max-width: 80%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                img {
                                    width: 12px;
                                    height: 12px;
                                }
                            }
                        }
                    }

                    .food-plus-box::-webkit-scrollbar { 
                        width: 0 !important 
                    }

                    .food-plus-box{ 
                        -ms-overflow-style: none; 
                        overflow: -moz-scrollbars-none; 
                    }

                    .beizhu {
                        display: flex;
                        font-size: 12px;
                    }

                    .img-card-box {
                        width: 330px;
                        overflow-x: auto;
                        display: flex;

                        .img-item {
                            width: 35px;
                            height: 35px;
                            margin-right: 10px;

                            img {
                                width: 35px;
                                height: 35px;
                            }
                        }
                    }
                    .class-box {
                        display: flex;
                        justify-content: space-between;

                        .class-box-item {
                            text-align: center;

                            .title {
                                font-size: 12px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #242424;
                                line-height: 9px;
                            }

                            .time {
                                font-size: 12px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #9f9f9f;
                                line-height: 11px;
                                margin: 10px 0 3px;
                            }

                            .number {
                                font-size: 35px;
                                font-family: PingFang-SC-Heavy, PingFang-SC;
                                font-weight: 800;
                                color: #26c3a8;
                                line-height: 35px;
                            }

                            .number.high {
                                color: #ffe452;
                            }

                            .number.low {
                                color: #ff6e6e;
                            }

                            .unit {
                                font-size: 12px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #9f9f9f;
                                line-height: 11px;
                                margin: 5px 0 0;
                            }
                        }
                    }

                    .chart {
                        width: 100%;
                        height: 120px;

                        .charts {
                            width: 100%;
                            height: 100%;

                            div {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                .longPass{
                    width: 100%;
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    font-size: 19px;

                    .breakfast-value{
                        p{
                            width: 140px;
                        }
                        span{
                            margin-left: 10px;
                        }
                    }

                    img{
                        width: 25px;
                        height: 25px;
                    }

                    .food-list p{
                        display: flex;
                        align-items: center;
                        font-weight: 700;
                        img{
                            margin: 0px 10px;
                        }
                    }
                }
            }
        }

        &.dark{
            .date{
                background: #0c0c0c;
                transition: .3s;
                .select{
                    color: #c1c1c1;
                }
                & /deep/ .dateSelect{
                    .el-input__inner{
                        background-color: unset;
                        border-color: #7d7d7d;
                    }
                }
            }
            .data-card{
                background: unset;
                border-color: #2c2c2e;
                .breakfast-value{
                    color: #c1c1c1;
                }
                .breakfast{
                    .food-list{
                        background: #0c0c0c !important;
                        color: #c1c1c1;
                        p:nth-of-type(2){
                            color: #7f7f7f !important;
                        }
                    }
                    &::after{
                        background: #2c2c2e;
                    }
                }
                .longPass{
                    .food-list{
                        color: #c1c1c1;
                    }
                    .remote{
                        color: #c1c1c1;
                    }
                }
                .fluctuation{
                    &::after{
                        background: #2c2c2e;
                    }
                }
                .up-suger,
                .fluctuation-value{
                    background: #0f947d !important;
                }
                .intake{
                    .title{
                        color: #c1c1c1;
                    }
                    &>div>p{
                        color: #7f7f7f !important;
                        span{
                            color: #7f7f7f;
                        }
                    }
                }
                .insulin,
                .water{
                    &::before{
                        background: #2c2c2e !important;
                    }
                }
                .standard,
                .recover{
                    .before-meal,
                    .after-meal,
                    .meal-two{
                        p{
                            color: #7f7f7f !important;
                        }
                        em{
                            color: #e1e1e1 !important;
                        }
                        h4{
                            color: #0f947d !important;
                        }
                        span{
                            color: #d1d1d1 !important;
                        }
                    }
                }
                .remark-text{
                    p{
                        color: #c1c1c1 !important;
                        i{
                            color: #c1c1c1 !important;
                        }
                    }
                }
            }
            .dayDate{

                .center-box{
                    .name,
                    .number{
                        color: #c1c1c1 !important;
                    }
                }

                .isCgm{
                    .title{
                        color: #c1c1c1 !important;
                    }
                    .float-left{
                        color: #c1c1c1;
                        .right{
                            & > *{
                                color: #c1c1c1 !important;
                            }
                        }
                    }
                    .average{
                        color: #7f7f7f !important;
                    }

                    .indicator{
                        .main-con{
                            color: #c1c1c1 !important;
                        }
                    }
                }

                .date_list{
                    h3,
                    h1{
                        color: white !important;
                    }
                    p{
                        color: #ccc !important;
                    }
                }
                .tip_right{
                    p{
                        color: white !important;
                    }
                    h4{
                        color: white !important;
                    }
                }
            }
            .notDate{
                h3{
                    color: #c1c1c1 !important;
                }
            }
            .not_cgm{
                .date_list{
                    h3,h1{
                        color: #c1c1c1 !important;
                    }
                    p{
                        color: #7f7f7f !important;
                    }
                }
                .list{
                    p{
                        color: #c1c1c1 !important;
                    }
                    h4{
                        color: #c5c5c5 !important;
                    }
                }
            }

            .pumpInfo{
                .background{
                    background-color: #0c0c0c;
                    color: #c1c1c1;
                    .planButton{
                        color: #c1c1c1;
                    }
                }
            }

            .dateSelect {
                & /deep/ .el-input__inner{
                    height: 32px;

                    &::placeholder{
                        font-size: 12px;
                    }
                }

                & /deep/ .el-input__prefix{
                    top: -5px;
                }

                & /deep/ .el-input__suffix{
                    top: -4px;
                }
                & /deep/ .el-picker-panel{
                    background: #202025;
                    border-color: #0c0c0c;
                    .el-picker-panel__icon-btn,
                    .el-date-picker__header-label{
                        color: #c1c1c1;
                    }
                    .el-date-table th{
                        color: #c1c1c1;
                        border-color: #0c0c0c;
                    }
                    .prev-month span{
                        color: #c1c1c1;
                    }
                    .available{
                        span{
                            color: #fff;
                        }
                        &.current{
                            span{
                                background-color: var(--defalutActiveColor);
                            }
                        }
                    }
                    .normal.disabled div,
                    .next-month.disabled div{
                        background: #0c0c0c;
                        color: #7f7f7f;
                    }
                    .popper__arrow{
                        border-bottom-color: #202025;
                        &::after{
                            border-bottom-color: #202025;
                        }
                    }
                }
            }

            .add-remark{
                background: #0c0c0c !important;
                box-shadow: 0px 0px 5px #0c0c0c !important;
                .textarea-box{
                    border-color: #202025 !important;
                    textarea{
                        background: unset !important;
                        color: #c1c1c1 !important;
                    }
                }
                button{
                    &.el-button--default{
                        background: #0c0c0c;
                        border-color: #202025;
                        &:hover{
                            color: #606266 !important;
                            border-color: #202025 !important;
                        }
                    }
                    &.submit{
                        color: #fff !important;
                        &:hover{
                            color: #fff !important;
                        }
                    }
                }
            }

        }
    }

    @media screen {
        @media (max-width: 1366px) and (min-width: 768px) {
            .everyDay{
                .date{
                    .dateSelect{
                        width: 200px !important;
                    }
                }
                .scheme-module{
                    .data-card{
                        .breakfast,
                        .fluctuation{
                            padding: 10px 5px;
                        }

                        .value-list{
                            .standard,
                            .recover{
                                padding: 15px 5px;
                            }
                        }
                        .fluctuation{
                            .intake{
                                &>div{
                                    >p{
                                        font-size: 30px;
                                        span{
                                            margin-left: -5px;
                                        }
                                    }
                                }
                            }
                            .chart-main{
                                .ChartsLine{
                                    margin-top: -35px;
                                }
                            }
                        }
                    }
                }
            }
            

            .indicator{
                display: none !important;
            }
        }
    }

    .remoteBasalClass{
        &.basal{
            
        }
        .unit{
            left: ~'calc(50% + 25px)';
            &.time{
                left: ~'calc(50% + 14px)';
            }
        }

        .tabers{
            cursor: pointer;
            &.on::before{
                content: '';
                position: absolute;
                bottom: -15px;
                left: calc(50% - 15px);
                width: 30px;
                height: 7px;
                background-color: var(--defalutColor);
                border-radius: 5px;
            }
        }

        .timePeriod{
            width: 110px;
        }

        .hours{
            width: 100px;
            /deep/ .el-input__inner{
                padding-right: 15px;
                &::placeholder{
                    font-size: 12px;
                }
            }
        }
        .deleteBtn{
            background-color: unset;
            color: var(--black);
            border: unset;
        }

        .nbsp{
            width: 38px;
            height: 38px;
            background: #fff;
        }

        .addTime{
            border: none;
            padding-left: 0px;
            /deep/ span{
                display: flex;
                align-items: center;
                i{
                    font-size: 28px;
                    display: block;
                    transform: rotate(45deg);
                    margin-right: 10px;
                }
            }
            &:hover,
            &:focus{
                color: var(--defalutColor);
            }
        }

    }

    .remoteBtnC:hover{
        color: var(--black) !important;
        border-color: var(--black) !important;
        transition: .3s;
    }

    .remoteBtnS{
        color: var(--white) !important;
        border-color: var(--defalutColor) !important;
        background-color: var(--defalutColor) !important;
        &:hover{
            border-color: var(--defalutActiveColor) !important;
            background-color: var(--defalutActiveColor) !important;

        }
    }

    .remoteHistory{
        height: 650px;
        overflow-y: scroll;
        padding: 0px 20px;
        .lists{
            width: 100%;
            height: 45px;
            border-color: #c1c1c1;
            .title{
                font-size: 16px;
                font-weight: bolder;
                width: 120px;
            }
            .high{
                color: var(--bloodSugarHigh);
            }
            .low{
                color: var(--bloodSugarLow);
            }
            .normal{
                color: var(--defalutColor);
            }
        }
    }
    .loadingMore{
        width: 100%;
        height: 45px;
    }

    .badge{
        font-style: normal;
        display: inline-block;
        width: 15px;
        height: 15px;
        line-height: 15px;
        font-size: 8px;
        color: white;
        border-radius: 45px;
        background-color: var(--bloodSugarLow);
    }
    
    /deep/ .el-input-number__decrease:hover,
    /deep/ .el-input-number__increase:hover{
        color: var(--defalutColor);
    }

    /deep/ .el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),
    /deep/ .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
        border-color: var(--defalutColor); /* 设置你想要的边框颜色 */
    }

</style>

<style lang="less">
    .planButton{
        font-size: 18px;
        &:hover{
            color: var(--defalutColor);
        }
    }

    .hansHover{
        &:hover{
            color: unset !important;
        }
    }
    .remote-cont{
        .row{
            width: 400px;
            .text{
                width: 120px;
                text-align: center;
            }
            .remote-btn{
                width: 90px;
                text-align: center;
                padding: 5px;
                font-size: 14px;
                border: 1px solid #b5b5b5;
                border-radius: 5px;
                cursor: pointer;
                &.dose{
                    border: none;
                    background-color: var(--bloodSugarHigh);
                }
                &.basal{
                    border: none;
                    background-color: var(--defalutColor);
                }
                &.run{
                    border-color: var(--defalutColor);
                    color: var(--defalutColor);
                }
                &.record{
                    border: none;
                    background-color: var(--defaultBlue);
                }
            }
        }
    }

    .el-dialog,
    .el-tooltip__popper{
        border-radius: 10px;
    }

</style>